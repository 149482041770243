import axios from "axios";
import configApp from '../configApp.js';

//*********************************************************
export const getOrganizationsListService = () => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/organizations/list");
    return (response);
}

//*********************************************************
export const getPaginatedListOfOrganizations = (filter, page, order, pageSize) => {
    let params = "";

    if (filter.idCouncil != undefined) {
        params = params + "idCouncil=" + filter.idCouncil + "&";
    }

    if (filter.text != undefined && String(filter.text).length > 0) params = params + "text=" + filter.text + "&";

    if (page != undefined) {
        params = params + "_page=" + page + "&";
    }

    if (pageSize != undefined) {
        params = params + "_pagesize=" + pageSize + "&";
    }

    if (order != undefined) {
        params = params + "_orderby=" + order;
    }

    let response = axios.get(configApp.getParams().BACKEND_API_URL + "organizations/paginated-list?" + params);

    return (response);
}

//*********************************************************
export const getCouncilsService = () => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/catalog/councils");
    return (response);
}

//*********************************************************
export const getProvincesService = () => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/catalog/provinces");
    return (response);
}

//*********************************************************
export const saveOrganizationEditService = (params) => {

    if (params.id != undefined) {
        const response = axios.post(configApp.getParams().BACKEND_API_URL + "organizations/" + params.id + "/change-data", params);
        return (response);
    }
    else {
        const response = axios.post(configApp.getParams().BACKEND_API_URL + "organizations/new", params);
        return (response);
    }
}

//*********************************************************
export const removeOrganizationService = (id) => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "organizations/" + id + "/remove");

    // Devolver la respuesta, aunque para este tipo de operaciones venga vacía, para capturar el error en caso de que se produzca alguno
    return (response);
}