// Clase utilizada para funciones auxiliares
class configApp {

    getParams=function(){
        // eslint-disable-next-line
        return (APP_CONFIG);     
    }
}


export default new configApp();


