import axios from "axios";
import configApp from "../configApp";

//*********************************************************
export const getPlayersListService = () => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "players/list");
    return (response);
}

//*********************************************************
export const getPaginatedListOfPlayers = (filter, page, order, pageSize) => {
    let params = "";

    if (filter.idTeam != undefined) {
        params = params + "idTeam=" + filter.idTeam + "&";
    }

    if (filter.showDisabled != undefined && String(filter.showDisabled).length > 0) params = params + "showDisabled=" + filter.showDisabled + "&"; 

    if (filter.idAcademicYear != undefined) {
        params = params + "idAcademicYear=" + filter.idAcademicYear + "&";
    }

    if (filter.text != undefined && String(filter.text).length > 0) params = params + "text=" + filter.text + "&";

    if (page != undefined) {
        params = params + "_page=" + page + "&";
    }

    if (pageSize != undefined) {
        params = params + "_pagesize=" + pageSize + "&";
    }

    if (order != undefined) {
        params = params + "_orderby=" + order + "&";
    }

    let response = axios.get(configApp.getParams().BACKEND_API_URL + "players/paginated-list?" + params);
    return(response);
}

//*********************************************************
export const savePlayerEditService = (params) => {    
    if (params.id != undefined) {
        const response = axios.post(configApp.getParams().BACKEND_API_URL + "public/players/" + params.id + "/change-data", params);
        return (response);
    }
    else {
        const response = axios.post(configApp.getParams().BACKEND_API_URL + "players/new", params);
        return (response);
    }
}

//*********************************************************
export const saveMultiPlayersService = (params) => {
    const response = axios.post(configApp.getParams().BACKEND_API_URL + "players/create-multiple", params);
    return (response);
}

//*********************************************************
export const changeEnabledPlayerStateService = (player, disabled) => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "players/" + player.id + "/set-disabled/" + disabled + "/");
    return (response);
}

//*********************************************************
export const removePlayerService = (id) => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "players/" + id + "/remove");
    
    // Se devuelve la respuesta para detectar el error en caso de que se produjera 
    return (response);
}