import React from "react";
import { inject, observer } from "mobx-react";
import { reaction } from 'mobx';
import { Col, Layout, message, Row } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import './Activation.scss';

const { Content } = Layout;

class Activation extends React.Component {

    //*********************************************************
    constructor(props) {
        super(props);
        this.state = {
            seconds: 1
        }

        this.reactions = [];
    }

    //*********************************************************
    componentDidMount() {
        setInterval(() => {
            if (this.state.seconds > 0)
                this.setState({ seconds: this.state.seconds - 1 })
        }, 1000);

        this.reactions.push(
            reaction(
                () => this.props.usersStore.loggedIn,
                () => {
                    if (this.props.usersStore.loggedIn) {
                        this.props.history.push('/main');
                    }
                }
            ), 
            reaction(
                () => this.props.usersStore.error,
                () => {
                    const errorMessage = this.props.usersStore.error;
                    if (errorMessage != undefined && errorMessage.length > 0) {
                        message.error(errorMessage);
                        this.props.history.push('/');
                    }
                }
            ), 
            reaction(
                () => this.props.generalStore.error,
                () => {
                    if(this.props.generalStore.error == null){
                        this.props.history.push('/');
                    }                    
                }
            )
        )
    }

    //*********************************************************
    handleSetTimeout = (token) => {
        this.props.usersStore.doActionAuthenticationByToken(token);
    }

    //*********************************************************
    render() {
        const location = window.location.href;
        const tk = location.split('=');
        const token = tk[1];

        if (this.state.seconds == 0) {
            this.handleSetTimeout(token);
        }

        return (
            <>
                <Layout className="activation-layout">
                    <Content>
                        <Row justify="center" align="bottom">
                            <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 20 }}>
                                <div className="activation-dialog">
                                    <h2 className="app-subtitle">Validando cuenta</h2>
                                    <LoadingOutlined className="login-form-icon"/>
                                </div>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </>
        )
    }

}

export default inject('usersStore', 'generalStore')(observer(Activation));