import React from "react";

import "./Login.scss";
import "../fonts.scss";
import { Layout, Row, Col, Form, Input, Button, Switch, message, Checkbox, Modal, Cascader } from 'antd';
import { IdcardOutlined, LockOutlined, SyncOutlined, TeamOutlined, HomeOutlined, MailOutlined, UserOutlined, PhoneOutlined, LockFilled } from '@ant-design/icons';
import { observer, inject } from "mobx-react";
import { reaction } from 'mobx';
import cookie from 'react-cookies';

import * as Actions from '../stores/actionFlags.js';

import AuthenticationService from "../services/AuthenticationService.js";

import logoApp from "../assets/images/app-logo.png"
import logoCompany from "../assets/images/company-logo.png"
import iconoEmail from "../assets/images/IconoEmail.png"
import iconoContrasena from "../assets/images/IconoContrasena.png"

const { Content } = Layout;

class Login extends React.Component {

    //*********************************************************
    constructor(props) {
        super(props);

        this.rememberMe = false;
        this.teams = [];
        this.teamsByOrganization = [];

        this.state = {
            showSinglePlayerLogin: false,/* formulario acceso participante individual */
            showRemindUser: false,
            showTeamPlayerLogin: false,
            showTeams: false,
            showLogin: false, /* formulario acceso equipo */
            disabled: false,
            cascaderValue: null,
            idOrganization: 0,
            idTeam: 0,
            isChecked: false,
            showRegisterUser: false,
            isPolicyAccepted: false,
            onChange: false
        }
        this.formRef = React.createRef();
        this.reactions = [];
    }

    //*********************************************************
    componentDidUpdate() {
        if (this.props.usersStore.loggedIn)
            this.props.history.push('/main');
    }

    //*********************************************************
    componentDidMount() {
        this.handleLoadOrganizationsData();

        this.componentDidUpdate();

        this.reactions.push(
            reaction(
                () => this.props.usersStore.loggedIn,
                () => {
                    if (this.props.usersStore.loggedIn == true) {
                        this.props.history.push('/main');
                    } else {
                        message.error("Desde aquí solamente pueden acceder los usuarios que no pertenecen a ninguna organización");
                    }
                }
            ),
            reaction(
                () => this.props.usersStore.userLogged,
                () => {
                    if (this.props.usersStore.userLogged.resetPassword) {
                        this.props.history.push('/recovery');
                    }
                }
            ),
            reaction(
                () => this.state.showTeams,
                () => {
                    if (this.state.showTeams == true) {
                        this.handleLoadTeamsData();
                    }
                }
            ),
            reaction(
                () => this.props.generalStore.error || this.props.usersStore.sentUserEmailFlag,
                () => {
                    const sentUserEmailFlag = this.props.usersStore.sentUserEmailFlag;
                    const errorMessage = this.props.generalStore.error;

                    if (errorMessage != undefined && errorMessage.length > 0) {

                        // Ignorar los errores de tipo 401 que pueden provenir de otros componentes cuando se intentan entrar directamente a una URL no autorizada tecleándola
                        if (errorMessage.indexOf("401") <= 0) message.error(errorMessage);
                        this.props.generalStore.doActionRemoveGeneralError();
                    }
                    else if (sentUserEmailFlag) {
                        message.success("Se han enviado los nuevos datos de acceso al correo electrónico del usuario ");
                        this.setState({ showRemindUser: false });
                    }
                }
            ),
            reaction(
                () => this.props.generalStore.error || this.props.usersStore.sentUserActivationFlag,
                () => {
                    const sentUserActivationFlag = this.props.usersStore.sentUserActivationFlag;
                    const errorMessage = this.props.generalStore.error;

                    if (errorMessage != undefined && errorMessage.length > 0) {

                        // Ignorar los errores de tipo 401 que pueden provenir de otros componentes cuando se intentan entrar directamente a una URL no autorizada tecleándola
                        if (errorMessage.indexOf("401") <= 0) message.error(errorMessage);
                        this.props.generalStore.doActionRemoveGeneralError();
                    }
                    else if (sentUserActivationFlag) {
                        message.success("Se ha enviado un correo eléctronico a la dirección introducida para la activación de su cuenta");
                        this.setState({ showRegisterUser: false });
                    }
                }
            )
        );

        if (!AuthenticationService.isUserLoggedIn()) {
            let refreshToken = cookie.load(process.env.REACT_APP_COOKIE_REFRESHTOKEN);

            if (this.props.location.search.indexOf("&srrtk") >= 0) {
                refreshToken = this.props.location.search.substring(this.props.location.search.indexOf("&srrtk") + 7);
            }

            if (refreshToken != null && refreshToken.length > 0) {
                this.props.usersStore.doActionRememberMe(refreshToken);
            }
        }
    }

    //*********************************************************
    handleLoadOrganizationsData = () => {
        if (this.props.organizationsStore != undefined) {
            this.props.organizationsStore.doActionGetProvincesList();
            this.props.organizationsStore.doActionGetCouncilsList();
            this.props.organizationsStore.doActionGetOrganizationsList();
        }
    }

    //*********************************************************
    handleLoadTeamsData = () => {
        if (this.props.teamsStore != undefined) {
            this.props.teamsStore.doActionGetTeamsList();
        }
    }
    //*********************************************************
    componentWillUnmount() {
        this.reactions.forEach((dispose) => dispose());
    }

    //*********************************************************
    handleRemindUserSubmit = (values) => {
        this.props.usersStore.doActionRemindUser(values.email);
    }
    //*********************************************************
    handleSelectedOrganization = (value) => {
        this.teams = this.props.teamsStore.teams.list;

        //this.setState({ onChange: true })

        if (this.state.idTeam != 0) {
            this.setState({ showLogin: false, idTeam: ' ', cascaderValue: 0 });
        }

        if (value == undefined) {
            this.setState({ showTeams: false, showLogin: false })
        }
        else if (value[2] == -1) {
            Modal.info({
                title: "El colegio al que perteneces no está dado de alta",
                content: "Consulta con tu profesor/a"
            })
        }
        else {
            //this.teamsByOrganization = this.props.teamsStore.teams.list.filter(item => item.organization.id == value[2]);
            this.setState({ cascaderValue: value[2], showTeams: true, idOrganization: value[2], onChange: true });
            this.handleLoadTeamsData();
        }
    }

    //*********************************************************
    handleSelectedTeam = (value) => {
        this.setState({ onChange: false })
        if (value == undefined) {
            this.setState({ showLogin: false })
        }
        else if (value[0] == -1) {
            Modal.info({
                title: "El colegio que has escogido no está dado de alta",
                content: "Consulta con tu profesor/a"
            })
        }
        else {
            this.setState({ cascaderValue: value[0], showLogin: true, idTeam: value[0] });
        }
    }

    //*********************************************************
    displayRender = (labels) => labels[labels.length - 1];

    //*********************************************************
    handlePlayerLoginSubmit = (values) => {
        this.props.usersStore.doActionPlayerLogin(values.username, values.password, this.rememberMe.toString());
    }

    //*********************************************************
    handleTeamPlayerLoginSubmit = (values) => {
        values.idOrganization = this.state.idOrganization;
        values.idTeam = this.state.idTeam;
        this.props.usersStore.doActionTeamPlayerLogin(values.username, values.password, values.idOrganization, values.idTeam);
    }

    //*********************************************************    
    handleRegisterUserSubmit = (values) => {        
        this.formRef.current
            .validateFields()
            .then((values) => {
                values.idOrganization = 1;
                this.props.usersStore.doActionRegisterUser(values);
            })
            .catch((errorInfo) => {
                console.log('Campos sin rellenar:', errorInfo);
            });
    }
    //*********************************************************
    showDataPrivacy = () => {
        Modal.info({
            title: "Política de privacidad",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel tincidunt augue. Cras venenatis pulvinar lorem ac convallis. Morbi nisl ante, efficitur quis tellus id, semper accumsan ipsum. Duis fringilla dolor vel mauris tincidunt, id rutrum dui hendrerit. Nunc bibendum enim consectetur elit tempor molestie. Mauris dictum eget elit nec sodales. Praesent tristique mauris id lacus lacinia, vitae egestas mi ullamcorper"
        })
    }

    //*********************************************************
    handleCheckboxChange = () => {
        this.setState({ isChecked: !this.state.isChecked });
    };
    //**********************************************************
    handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        this.setState({ isPolicyAccepted: isChecked }, () => {
            if (!isChecked) {
                // Forzar validación manualmente si el checkbox se desmarca
                this.formRef.current.validateFields(['acceptPolicy']);
            }
        });
    };
    //*********************************************************
    render() {

        const isFetchingTeamPlayer = this.props.generalStore.processing.includes(Actions.LOGIN_TEAM_PLAYER);
        const isFetchingPlayer = this.props.generalStore.processing.includes(Actions.LOGIN_PLAYER);

        const isSendingUserEmail = this.props.generalStore.processing.includes(Actions.REMINDUSER);
        const isRegisteringUser = this.props.generalStore.processing.includes(Actions.REGISTER_USER);

        const organizationsStore = this.props.organizationsStore;
        const teamsStore = this.props.teamsStore;

        const councils = organizationsStore.councils == undefined ? [] : organizationsStore.councils;
        const provinces = organizationsStore.provinces == undefined ? [] : organizationsStore.provinces;
        const organizations = organizationsStore.organizations.list == undefined ? [] : organizationsStore.organizations.list;
        const teams = teamsStore.teams.list == undefined ? [] : teamsStore.teams.list;

        const teamsByOrganization = teams == undefined ? [] : teams?.filter(item => item.organization.id == this.state.idOrganization);

        var optionsForTeams = [];

        teamsByOrganization.map(team => {
            return (
                optionsForTeams.push({
                    value: team.id,
                    label: team.name
                })
            )
        })

        var options = [];

        provinces.filter(item => item.id != 4).map((item) => {
            return (
                options.push(
                    {
                        value: item.id,
                        label: item.name,
                        children: councils.filter((council) => council.province.id == item.id).map(council => ({
                            value: council.id,
                            label: council.name,
                            children: organizations.filter((organization) => organization.council.id == council.id).map(organization => (
                                {
                                    value: organization.id,
                                    label: organization.name
                                }
                            )),/* .concat([{ value: -1, label: "Sin determinar" }]) */
                            disabled: organizations.filter((organization) => organization.council.id == council.id).length == 0 ? true : false
                        }))
                    }
                )
            )
        })

        return (
            <>
                <Layout className="login-layout">
                    <Content>
                        <div>
                            <Row justify="center" align="top">
                                <Col className="center-content" xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 20 }}>
                                    <img className="logo-app" src={logoApp} alt="Logo de Gusarapos"></img>
                                    <h2 className="app-subtitle">JUEGA Y MEJORA TU ECOSISTEMA</h2>
                                </Col>
                            </Row>
                            {/* INICIO BOTONES FORMULARIO INICIAL*/}
                            <Row justify="center" align="center" className={this.state.showSinglePlayerLogin || this.state.showTeamPlayerLogin || this.state.showRegisterUser || this.state.showRemindUser ? 'invisible' : 'visible'}>
                                <Row className="first-form">
                                    <Col className="option" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10 }}>
                                        <Button type="primary" className="login-form-button" onClick={() => { this.setState({ showSinglePlayerLogin: true }) }}>Jugador Individual</Button>
                                        <div className='description' justify='center' align='center'>
                                            <p className="text">Si eres un jugador que juega a título individual y no pertenece a ningún colegio u organización, pulsa este botón</p>
                                        </div>

                                    </Col>

                                    <Col className="option" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10 }}>
                                        <Button type="primary" className="login-form-button" onClick={() => { this.setState({ showTeamPlayerLogin: true }) }}>Jugador de Equipo</Button>
                                        <div className='description' justify='center' align='center'>
                                            <p className="text">Si eres un jugador de equipo, pulsa este botón</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Row>
                            {/* FIN BOTONES FORMULARIO INICIAL*/}

                            {/* INICIO LOGIN EQUIPO */}
                            <Row justify="center" align="bottom" className={this.state.showTeamPlayerLogin ? "visible" : "invisible"}>
                                <Col xs={{ span: 18 }} sm={{ span: 12 }} xl={{ span: 8 }}>
                                    <Form className="login-form" onFinish={this.handleTeamPlayerLoginSubmit}>
                                        <Form.Item xs={{ div: 18 }} sm={{ div: 12 }} xl={{ div: 8 }} className="form-field" value="idOrganization" autoComplete="false" rules={[{ required: true, message: 'Selecciona tu colegio u organización' }]} >
                                            {<HomeOutlined className="loginplayer-form-icon-cascader" name="idOrganization" />}<Cascader
                                                className="cascader"
                                                onChange={this.handleSelectedOrganization}
                                                options={options}
                                                displayRender={this.displayRender}
                                                placeholder="Colegio u organización"
                                                showSearch
                                            />
                                        </Form.Item>

                                        <Form.Item xs={{ div: 18 }} sm={{ div: 12 }} xl={{ div: 8 }} className={this.state.showTeams ? "visible" : "invisible"} value="idTeam" autoComplete="false" rules={[{ required: true, message: 'Selecciona tu equipo' }]} >
                                            {<TeamOutlined className="loginplayer-form-icon-cascader" name={this.state.idTeam} />}<Cascader
                                                className="cascader"
                                                onChange={this.handleSelectedTeam}
                                                options={optionsForTeams}
                                                value={this.state.onChange ? [] : this.state.cascaderValue}
                                                displayRender={this.displayRender}
                                                placeholder="Equipo"
                                                showSearch
                                            />
                                        </Form.Item>

                                        <Form.Item className={this.state.showLogin ? "visible" : "invisible"} name="username" autoComplete="true" rules={[{ required: true, message: 'Introduce tu nombre de usuario:' }]}>
                                            <Input prefix={<UserOutlined className="login-form-icon" />} placeholder="Usuario" />
                                        </Form.Item>

                                        <Form.Item className={this.state.showLogin ? "visible" : "invisible"} name="password" autoComplete="false" rules={[{ required: true, message: 'Introduce tu contraseña:' }]}>
                                            <Input.Password prefix={<LockOutlined className="login-form-icon" />} type="password" placeholder="Contraseña" />
                                        </Form.Item>

                                        {<Form.Item >
                                            {!isFetchingTeamPlayer && <Button type="primary" htmlType="submit" className="login-form-button">ENTRAR</Button>}
                                            {isFetchingTeamPlayer && <Button type="primary" disabled style={{ backgroundColor: `#AAAAAA` }} className="login-form-button "><SyncOutlined spin /></Button>}
                                        </Form.Item>}
                                    </Form>
                                    <Row className="volver" justify="center" align="bottom">
                                        <Button type="link" className="bottom-link" onClick={() => this.setState({ showTeamPlayerLogin: false })}><div class="label_question">Volver</div></Button>
                                    </Row>
                                </Col>
                            </Row>
                            {/* FIN LOGIN EQUIPO */}

                            {/* INICIO LOGIN PARTICIPANTE INDIVIDUAL */}
                            <Row justify="center" align="bottom" className={this.state.showSinglePlayerLogin ? "visible" : "invisible"}>
                                <Col xs={{ span: 18 }} sm={{ span: 12 }} xl={{ span: 8 }}>
                                    <Form className="login-form" initialValues={{ remember: true }} onFinish={this.handlePlayerLoginSubmit}>                                     
                                        <Form.Item className="form-field" name="username" autoComplete="true" rules={[{ required: true, message: 'Introduce tu email' }]}>
                                            <Input prefix={<MailOutlined className="login-form-icon" />} placeholder="Email" />
                                        </Form.Item>

                                        <Form.Item className="form-field" name="password" autoComplete="false" rules={[{ required: true, message: 'Introduce tu contraseña' }]}>
                                            <Input.Password prefix={<LockOutlined className="login-form-icon" />} type="password" placeholder="Contraseña" />
                                        </Form.Item>

                                        {/* <Form.Item className="form-field rememberme" >
                                            <span className="text">Recordarmee</span>
                                            <Form.Item name="rememberme" valuePropName="checked" noStyle>
                                                <Switch onChange={this.handleRememberMeChange} checkedChildren="si" unCheckedChildren="no" />
                                            </Form.Item>
                                        </Form.Item> */}

                                        {<Form.Item >
                                            {!isFetchingPlayer && <Button type="primary" htmlType="submit" className="login-form-button">ENTRAR</Button>}
                                            {isFetchingPlayer && <Button type="primary" disabled style={{ backgroundColor: `#AAAAAA` }} className="login-form-button"><SyncOutlined spin /></Button>}
                                        </Form.Item>}
                                    </Form>

                                    <Row justify="center" align="bottom">
                                        <Button type="link" className="bottom-link" onClick={() => this.setState({ showRemindUser: true, showSinglePlayerLogin: false })} ><div class="label_question"> ¿Has olvidado tu contraseña?</div></Button>
                                    </Row>

                                    <Row justify="center" align="bottom">
                                        <Button type="link" className="bottom-link" onClick={() => this.setState({ showRegisterUser: true, showSinglePlayerLogin: false })} ><div class="label_question"> ¿No tienes una cuenta? ¡Regístrate!</div></Button>
                                    </Row>

                                    <Row className="volver" justify="center" align="bottom">
                                        <Button type="link" className="bottom-link" onClick={() => this.setState({ showSinglePlayerLogin: false })}><div class="label_question"> Volver</div></Button>
                                    </Row>                                     
                                </Col>
                            </Row>
                            {/* FIN LOGIN PARTICIPANTE INDIVIDUAL */}


                            {/* INICIO FORMULARIO RECORDAR SESIÓN */}
                            <Row justify="center" align="bottom" className={this.state.showRemindUser ? "visible" : "invisible"}>
                                <Col xs={{ span: 18 }} sm={{ span: 12 }} xl={{ span: 8 }}>

                                    <Form className="reminduser-form" initialValues={{ remember: true }} onFinish={this.handleRemindUserSubmit} >
                                        <Form.Item className="form-field" name="email" rules={[{ required: true, type: 'email', message: 'Introduce un correo electrónico válido' }]} >
                                            <Input prefix={<MailOutlined className="reminduser-form-icon" />} placeholder="Correo electrónico" />
                                        </Form.Item>

                                        <Form.Item>
                                            {!isSendingUserEmail && <Button type="primary" htmlType="submit" className="remind-form-button">RECORDAR ACCESO</Button>}
                                            {isSendingUserEmail && <Button type="primary" disabled style={{ backgroundColor: `#AAAAAA` }} className="remind-form-button"><SyncOutlined spin /></Button>}
                                        </Form.Item>
                                    </Form>
                                    <Row  className="volver" justify="center" align="bottom">
                                        <Button type="link" className="bottom-link" onClick={() => this.setState({ showRemindUser: false, showSinglePlayerLogin: true })}><div class="label_question">Volver</div></Button>
                                    </Row>
                                </Col>
                            </Row>
                            {/* FIN FORMULARIO RECORDAR SESIÓN*/}
                            
                            {/* INICIO CREACIÓN JUGADOR INDIVIDUAL */}
                            <Row justify="center" align="bottom" className={this.state.showRegisterUser ? "visible" : "invisible"}>
                                <Col xs={{ span: 18 }} sm={{ span: 12 }} xl={{ span: 8 }}>

                                    <Form
                                        className="registeruser-form"
                                        initialValues={{ remember: true }}
                                        ref={this.formRef}  // Asignamos la referencia al formulario
                                        onFinish={this.handleRegisterUserSubmit}
                                    >
                                        <div className='description' justify='center' align='center'>
                                            <h2 className='text'> Crear cuenta jugador individual </h2>
                                            <p className='text'>Rellene los campos para dar de alta al jugador individual</p>
                                        </div>

                                        <Form.Item className="form-field" name="name" autoComplete="false" rules={[{ required: true, message: 'Introduce tu nombre' }]} >
                                            <Input prefix={<UserOutlined className="registeruser-form-icon" />} id="registeruser-name" placeholder="Nombre" />
                                        </Form.Item>

                                        <Form.Item className="form-field" name="surname" autoComplete="false" rules={[{ required: true, message: 'Introduce tus apellidos' }]} >
                                            <Input prefix={<IdcardOutlined className="registeruser-form-icon" />} id="registeruser-surname" placeholder="Apellidos" />
                                        </Form.Item>

                                        <Form.Item className="form-field" name="username" autoComplete="false" rules={[{ required: true, message: 'Introduce tu usuario' }]} >
                                            <Input prefix={<UserOutlined className="registeruser-form-icon" />} id="registeruser-username" placeholder="Nick" />
                                        </Form.Item>

                                        <Form.Item className="form-field" name="email" autoComplete="false" rules={[{ required: true, message: 'Introduce un correo electrónico válido' }]} >
                                            <Input prefix={<MailOutlined className="registeruser-form-icon" />} id="registeruser-email" placeholder="Correo electrónico" />
                                        </Form.Item>

                                        <Form.Item className="form-field" name="phone" autoComplete="false" rules={[{ required: false, message: 'Introduce tu número de teléfono' }]} >
                                            <Input prefix={<PhoneOutlined className="registeruser-form-icon" />} id="registeruser-phone" placeholder="Teléfono (opcional)" />
                                        </Form.Item>

                                        <Form.Item className="form-field" name="password" autoComplete="false" rules={[{ required: true, message: 'Introduce una contraseña' }, { min: 6, message: 'La contraseña debe tener al menos 6 caracteres' }]} >
                                            <Input.Password prefix={<LockOutlined className="registeruser-form-icon" />} id="reminduser-password" placeholder="Contraseña" />
                                        </Form.Item>
                                        <Form.Item className="form-field" autoComplete="false" name="passwordRepeat"
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (getFieldValue('password') != '' && (value === undefined || getFieldValue('password') != value)) {
                                                            return Promise.reject(new Error('Las dos contraseñas introducidas no coinciden'));
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                })]}>
                                            <Input.Password prefix={<LockOutlined className="registeruser-form-icon" />} id="reminduser-password-repeat" placeholder="Repite Contraseña" />
                                        </Form.Item>                                      
                                        <Form.Item
                                            name="acceptPolicy"
                                            valuePropName="checked"
                                            rules={[{
                                                validator: (_, value) =>
                                                    value ? Promise.resolve() : Promise.reject('Acepta las condiciones')
                                            }]}
                                        >
                                            <Checkbox checked={this.state.isPolicyAccepted} onChange={this.handleCheckboxChange}>
                                                Acepto las condiciones y confirmo haber leído <a href="#" onClick={this.showDataPrivacy}>la política de privacidad</a>
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item>
                                            {!isRegisteringUser && (
                                                <Button
                                                    type="primary"
                                                    onClick={this.handleRegisterUserSubmit}  // Llamamos a la validación y el envío manualmente
                                                    className="register-form-button"
                                                >
                                                    <label className="label_buttom">CREAR CUENTA</label>
                                                </Button>
                                            )}
                                            {isRegisteringUser && (
                                                <Button
                                                    type="primary"
                                                    style={{ backgroundColor: `#AAAAAA` }}
                                                    className="register-form-button"
                                                >
                                                    <SyncOutlined spin />
                                                </Button>
                                            )}
                                        </Form.Item>
                                    </Form>

                                    <Row className="volver" justify="center" align="bottom">
                                        <Button type="link" className="bottom-link" onClick={() => this.setState({ showRegisterUser: false, showSinglePlayerLogin: true })}><div class="label_question">Volver</div></Button>
                                    </Row>
                                </Col>
                            </Row>
                            {/* FIN CREACIÓN JUGADOR INDIVIDUAL */}                           

                            {/* LOGO CHC CANTÁBRICO */}                           
                            <Row justify="center" align="top">
                                <Col className="center-content" xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 20 }} style={{ textAlign: 'center' }}>
                                    <a href="https://www.chcantabrico.es/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>
                                        <img
                                            className="logo-app logo-company"
                                            src={logoCompany}
                                            alt="Logo de Confederación Hidrográfica del Cantábrico"
                                            style={{ maxWidth: '100%', height: 'auto' }}
                                        />
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Content>
                </Layout>
            </>
        )
    }
}

export default inject('usersStore', 'generalStore', 'organizationsStore', 'teamsStore')(observer(Login));