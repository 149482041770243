import generalStore from './generalStore.js';
import usersStore from './usersStore.js';
import organizationsStore from './organizationsStore.js';
import teamsStore from './teamsStore.js';
import playersStore from './playersStore.js';

const rootStore = {
    generalStore,
    usersStore,
    organizationsStore,
    teamsStore,
    playersStore
};

export default rootStore;

//*********************************************************
export async function callServiceEventAware(flag, service, ...args) {

    generalStore.doActionStartService(flag);
    const response = await service(...args);
    generalStore.doActionEndService(flag);

    return response;
}