import React from "react";

import { Modal, Button, Row, Col } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

//Para poder utilizar 'this.props.history.push()' en un componente
import { withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";

import icon from '../assets/images/buttons/tienda.png';
import disabledIcon from '../assets/icons/disabledIcon.png';
import checkIcon from '../assets/icons/check.png';

import * as Actions from '../stores/actionFlags';

import GlobalFonts from "../assets/fonts/fonts";

import './StepsModal.scss';
import { reaction } from "mobx";
import { List } from "antd";
import { Empty } from "antd";
import { message } from "antd";

class StepsModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            title: '',
            icon: null,
            close: false
        };

        this.reactions = [];
    }

    //*********************************************************
    componentDidMount() {
        this.handleLoadSteps();

        this.reactions.push(
            reaction(
                () => this.props.show,
                () => {
                    if (this.props.show == true) {
                        this.setState({ show: this.props.show });
                    }
                    if (this.props.show == true && this.props.user.role.name == 'TEAMPLAYER') {
                        message.warning("Las mejoras del río solo las puede realizar el/la profesor/a.");
                    }
                }
            ), reaction(
                () => this.props.generalStore.error,
                () => {
                    const errorMessage = this.props.generalStore.error;
                    if (errorMessage != undefined && errorMessage.length > 0) {
                        message.error("Las mejoras del río solo las puede realizar el/la profesor/a.");
                        this.props.generalStore.doActionRemoveGeneralError();
                    }
                }
            )
        )
    }

    //*********************************************************
    handleLoadSteps() {
        this.props.teamsStore.doActionGetStepsList();
    }

    //*********************************************************
    handleChangeBackground = (step) => {
        if (step.price <= this.props.team.score) {
            var idTeam = this.props.team.id;
            var idStep = step.id;
            this.props.teamsStore.doActionChangeTeamsStep(idTeam, idStep);
            window.location.reload();
        } else if (this.props.user.role.name == 'PLAYER'){
            message.error("No tienes suficientes puntos para adquirir esta mejora. ¡Debes jugar a los minijuegos para obtener más puntos!")
        } else {
            message.error("Tu equipo no tiene suficientes puntos para adquirir esta mejora. ¡Debéis jugar a los minijuegos para obtener más puntos!")
        }
    }

    //*********************************************************
    render() {
        const originalSteps = this.props.teamsStore.steps == [] ? [] : this.props.teamsStore.steps;

        const lastStep = originalSteps.find((item, index) => index == originalSteps.length-1);

        const steps = originalSteps.filter(item => item.price != 0 && item != lastStep && item.level.num == this.props.level.num);

        const userLogged = this.props.user;

        const nextStep = originalSteps.find(item => item.order == this.props.team.step.order + 1);
        

        userLogged.role.name == 'TEAMPLAYER' ? steps.map(item => item.disabled = true) :
            steps.map((item) => {
                if (item != nextStep && item.order > nextStep?.order) {
                    item.disabled = true;
                }
        });

        return (
            <>
                <GlobalFonts />
                <Modal
                    className="stepsmodal"                    
                    title={
                        <Row justify='center' align="top">
                             <Col id="icon" sm={{ span: 4 }} md={{ span: 4 }}>
                                {<img className="modal-icon" src={icon} />}
                            </Col>
                            <Col className="title-background">
                                <div className="title">
                                    <p>TIENDA</p>
                                </div>
                            </Col>
                            <Col className="subtitle-background">
                                <div className="subtitle">
                                    <p>{this.props.level.title}</p>
                                </div>
                            </Col>
                        </Row>
                    }
                    closeIcon=
                    {
                        <Button onClick={this.props.close} shape="circle" id="close-button" >
                            <CloseCircleOutlined className="close-circle" />
                        </Button>
                    }
                    open={this.props.show}
                    width="max-content"
                    closable={true}
                    maskClosable={true}
                    footer={null}>
                    <div style={{ backgroundColor: '#0e3e41', textAlign: 'center' }}>
                        <List locale={{ emptyText: (<Empty description="No hay resultados" image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
                            dataSource={steps}
                            renderItem={(item) => (
                                <>
                                    <List.Item>
                                        <Row className={`list-item box-screen-mode`}>
                                            <Col className="data" md={{ span: 12 }} onClick={() => {!item.disabled && item.order >= nextStep?.order && this.handleChangeBackground(item)}}>
                                                <div className={`level ${item.disabled ? 'disabled' : item.order < nextStep?.order ? 'completed' : ''}`}>
                                                    <p className="title">{item.title}</p>
                                                    <div className="points">
                                                        <p>{item.price} puntos</p>
                                                    </div>
                                                    <img className="icon" src={require(`../assets/icons/steps/${item.icon}`)} />
                                                    {item.disabled &&
                                                        <img className="disabled-icon" src={disabledIcon} />
                                                    }
                                                    {!item.disabled && item.order < nextStep?.order &&
                                                        <img className="disabled-icon" src={checkIcon} />
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                </>
                            )}
                        >
                        </List>
                    </div>
                </Modal>

            </>
        )
    }
}

export default withRouter(inject('teamsStore', 'generalStore')(observer(StepsModal)));