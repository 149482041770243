import React from "react";

import { Modal, Button, Row, Col } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import { withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import { Content } from "antd/es/layout/layout.js";

import icon from '../assets/images/buttons/armario.png';

import fixed from '../assets/avatar/fijos.png';
//import star from '../assets/avatar/star.png';

import headEditOn from '../assets/avatar/headEdit-on.png';
import bodyEditOn from '../assets/avatar/bodyEdit-on.png';
import headEditOff from '../assets/avatar/headEdit-off.png';
import bodyEditOff from '../assets/avatar/bodyEdit-off.png';
import backgroundColorOn from '../assets/avatar/bgEdit-on.png';
import backgroundColorOff from '../assets/avatar/bgEdit-off.png';
import save from '../assets/avatar/save.png';

import GlobalFonts from "../assets/fonts/fonts";

import './AvatarModal.scss';
import { reaction } from "mobx";
import { Image } from "antd";

class AvatarModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            title: '',
            icon: null,
            backgroundColor: 'rgb(3, 161, 154)',
            hairColor: 'default',
            skinColor: 'default',
            uniformColor: 'default',
            scarfColor: 'default',
            palette: false,
            bodyEdit: false,
            headEdit: false,
            intentionalReload: false
        };

        this.reactions = [];
    }

    //*********************************************************
    componentDidMount() {
        //this.handleCustomizeAvatar();
        this.reactions.push(
            reaction(
                () => this.props.show,
                () => {
                    if (this.props.show) {
                        this.setState({ show: true })
                    }
                }
            ),
            reaction(
                () => this.props.userTeam,
                () => {
                    const team = this.props.userTeam;
                    const jsonConfig = team.image_config;
                    if (jsonConfig != undefined) {
                        var bg = jsonConfig.split(',"')[0].split(":")[1].replace(/"/g, '');
                        var hair = jsonConfig.split(',"')[1].split(":")[1].replace(/"/g, '');
                        var body = jsonConfig.split(',"')[2].split(":")[1].replace(/"/g, '');
                        var suit = jsonConfig.split(',"')[3].split(":")[1].replace(/"/g, '');
                        var scarf = jsonConfig.split(',"')[4].split(":")[1].replace("}", "").replace(/"/g, '');
                        this.setState({
                            backgroundColor: bg, hairColor: hair,
                            skinColor: body, uniformColor: suit, scarfColor: scarf
                        });
                    }
                }
            )
        )
    }

    //*********************************************************
    componentDidUpdate() {
        this.reactions.push(
            reaction(
                () => this.props.show,
                () => {
                    if (this.props.show) {
                        this.setState({ show: true })
                    }
                }
            ),
            reaction(
                () => this.props.userTeam,
                () => {
                    const team = this.props.userTeam;
                    const jsonConfig = team.image_config
                    if (jsonConfig != undefined) {
                        var bg = jsonConfig.split(',"')[0].split(":")[1].replace(/"/g, '');
                        var hair = jsonConfig.split(',"')[1].split(":")[1].replace(/"/g, '');
                        var body = jsonConfig.split(',"')[2].split(":")[1].replace(/"/g, '');
                        var suit = jsonConfig.split(',"')[3].split(":")[1].replace(/"/g, '');
                        var scarf = jsonConfig.split(',"')[4].split(":")[1].replace("}", "").replace(/"/g, '');
                        this.setState({
                            backgroundColor: bg, hairColor: hair,
                            skinColor: body, uniformColor: suit, scarfColor: scarf
                        });
                    }
                }
            )
        )
    }

    // //*********************************************************
    // closeModal() {
    //     return this.props.close();
    // }

    //*********************************************************
    handleSaveJson = () => {
        var idTeam = this.props.userTeam.id;

        var config = {
            background: this.state.backgroundColor,
            hair: this.state.hairColor,
            body: this.state.skinColor,
            suit: this.state.uniformColor,
            scraf: this.state.scarfColor
        }

        var jsonData = JSON.stringify(config);

        this.setState({ show: false })

        this.props.teamsStore.doActionSaveJsonTeam(idTeam, jsonData);

        setTimeout(() => {
            this.setState({ intentionalReload: true }, () => {
                window.location.reload();
            });
        }, 1500);
    }

    //*********************************************************
    render() {
        const bgColors = [
            { name: 'blue', rgb: { r: 0, g: 0, b: 255 }, rgba: 'rgb(24, 118, 247)' },
            { name: 'green', rgb: { r: 0, g: 255, b: 0 }, rgba: 'rgb(24, 222, 104)' },
            { name: 'yellow', rgb: { r: 255, g: 255, b: 0 }, rgba: 'rgb(237, 230, 17)' },
            { name: 'orange', rgb: { r: 255, g: 128, b: 0 }, rgba: 'rgb(237, 154, 17)' },
            { name: 'black', rgb: { r: 255, g: 0, b: 0 }, rgba: 'rgb(46, 46, 46)' },
            { name: 'purple', rgb: { r: 128, g: 0, b: 128 }, rgba: 'rgb(163, 27, 232)' },
            { name: 'red', rgb: { r: 0, g: 0, b: 0 }, rgba: 'rgb(185, 18, 18)' },
            { name: 'white', rgb: { r: 255, g: 255, b: 255 }, rgba: 'rgb(245, 240, 240)' },
            { name: 'grey', rgb: { r: 128, g: 128, b: 128 }, rgba: 'rgb(155, 155, 155)' },
            { name: 'pink', rgb: { r: 255, g: 0, b: 128 }, rgba: 'rgb(214, 15, 144)' },
            { name: 'default', rgb: { r: 3, g: 161, b: 154 }, rgba: 'rgb(3, 161, 154)' }
        ];

        const skinColors = [
            { name: '1', rgb: { r: 239, g: 174, b: 216 }, hex: '#fff1e4' },
            { name: '2', rgb: { r: 128, g: 64, b: 0 }, hex: '#f8d0c8' },
            { name: '3', rgb: { r: 128, g: 128, b: 128 }, hex: '#e2998b' },
            { name: '4', rgb: { r: 255, g: 255, b: 0 }, hex: '#9f7368' },
        ];

        const hairColors = [
            { name: 'red', rgba: 'rgb(239, 174, 216, 1)', hex: '#bb2a17' },
            { name: 'brown', rgba: 'rgb(128, 64, 0)', hex: '#c1641e' },
            { name: 'grey', rgba: 'rgb(155, 155, 155)', hex: '#c5bdaf' },
            { name: 'default', rgba: 'rgb(237, 230, 17)', hex: '#f1b65c' },
            { name: 'black', rgba: 'rgb(46, 46, 46)', hex: '#1b130a' },
            { name: 'purple', rgba: 'rgb(245, 240, 240)', hex: '#5e3757' }
        ];

        const uniformColors = [
            { name: 'blue', rgba: 'rgb(62, 53, 105, 1)', hex: '#413a76' },
            { name: 'brown', rgba: 'rgb(153, 88, 50)', hex: '#995832' },
            { name: 'grey', rgba: 'rgb(155, 155, 155)', hex: '#c5bdaf' },
            { name: 'default', rgba: 'rgb(214, 185, 164)', hex: '#d6b9a4' },
            { name: 'red', rgba: 'rgb(107, 39, 60)', hex: '#6b273c' },
            { name: 'green', rgba: 'rgb(23, 141, 21)', hex: '#178D15' }
        ];

        const scarfColors = [
            { name: 'default', rgba: 'rgb(214, 185, 164)', hex: '#70a2ad' },
            { name: 'blue', rgba: 'rgb(62, 53, 105, 1)', hex: '#36a9e1' },
            { name: 'yellow', rgba: 'rgb(153, 88, 50)', hex: '#fcbf49' },
            { name: 'orange', rgba: 'rgb(155, 155, 155)', hex: '#f19700' },
            { name: 'black', rgba: 'rgb(107, 39, 60)', hex: '#120500' },
            { name: 'green', rgba: 'rgb(23, 141, 21)', hex: '#95bb20' }
        ];

        return (
            <>
                <GlobalFonts />
                <Modal
                    className="avatarsmodal"
                    title={
                        <Row justify='center' align="top">
                            <Col id="icon" xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 4 }}>
                                {<img className="modal-icon" src={icon} />}
                            </Col>
                            <Col className="title-background"/*  xs={{ span: 20 }} sm={{ span: 16 }} md={{ span: 16 }} */>
                                ARMARIO
                            </Col>
                        </Row>
                    }
                    closeIcon=
                    {
                        <Button onClick={() => this.props.close()} shape="circle" id="close-button" >
                            <CloseCircleOutlined className="close-circle" />
                        </Button>
                    }
                    open={this.props.show}
                    closable={true}
                    maskClosable={true}
                    footer={null}
                >
                    <Content>
                        <div className="avatar_container" style={{ backgroundColor: this.state.backgroundColor }}>
                            {/* <img className="avatar_background" src={avatarBackground} style={{ filter: this.state.palette ? `hue-rotate(${this.state.angle}deg)` : 'none' }} /> */}
                            <img className="fixed" src={fixed} alt="hat-and-boots" />
                            <img className="scarf" src={require(`../assets/avatar/scarf/${this.state.scarfColor != 'default' ? this.state.scarfColor + '.png' : 'default.png'}`)} alt="scarf" />
                            <img className="suit" src={require(`../assets/avatar/uniform/${this.state.uniformColor != 'default' ? this.state.uniformColor + '.png' : 'default.png'}`)} alt="suit" />
                            <img className="hair" src={require(`../assets/avatar/hair/${this.state.hairColor != 'default' ? this.state.hairColor + '.png' : 'default.png'}`)} alt="hair" />
                            <img className="body" src={require(`../assets/avatar/body/${this.state.skinColor != 'default' ? this.state.skinColor + '.png' : 'default.png'}`)} alt="body" />
                        </div>
                        <div className="name">
                            {this.props.userTeam?.name}
                        </div>

                        <div className="menu">
                            <Button onClick={() => this.setState({ palette: !this.state.palette, headEdit: false, bodyEdit: false })}><Image src={this.state.palette ? backgroundColorOn : backgroundColorOff} preview={false} /></Button>
                            <br />
                            <Button onClick={() => this.setState({ palette: false, headEdit: !this.state.headEdit, bodyEdit: false })}><Image src={this.state.headEdit ? headEditOn : headEditOff} preview={false} /></Button>
                            <br />
                            <Button onClick={() => this.setState({ palette: false, headEdit: false, bodyEdit: !this.state.bodyEdit })}><Image src={this.state.bodyEdit ? bodyEditOn : bodyEditOff} preview={false} /></Button>
                        </div>
                        {this.props.user.role.id != 3 &&
                            <div className="save-edit" onClick={() => { this.handleSaveJson() }}>
                                <span className="save">Guardar</span>
                                <img className="save-icon" src={save} />
                            </div>
                        }
                        <div className={`${this.state.palette ? 'color-palette' : `${this.state.headEdit ? 'head-edit' : `${this.state.bodyEdit ? 'body-edit' : 'invisible'}`}`}`}>
                            {this.state.palette && bgColors.map((item, index) =>
                                <div className="color" style={{ backgroundColor: item.rgba }} onClick={() => this.setState({ backgroundColor: item.rgba })}></div>
                            )}

                            {this.state.headEdit &&
                                <div>
                                    <p className="text">
                                        COLOR DE PIEL
                                    </p>
                                    <br />
                                    <div className="color4">
                                        {skinColors.map((item) =>
                                            <div className="color" style={{ backgroundColor: item.hex }} onClick={() => this.setState({ skinColor: item.name })}></div>
                                        )}
                                    </div>
                                    <p className="text">
                                        COLOR DE PELO
                                    </p>
                                    <br />
                                    <div className="color6">
                                        {hairColors.map((item) =>
                                            <div className="color" style={{ backgroundColor: item.hex }} onClick={() => this.setState({ hairColor: item.name })}></div>
                                        )}
                                    </div>
                                </div>
                            }

                            {this.state.bodyEdit &&
                                <div>
                                    <p className="text">
                                        PAÑUELO
                                    </p>
                                    <br />
                                    <div className="color6">
                                        {scarfColors.map((item) =>
                                            <div className="color" style={{ backgroundColor: item.hex }} onClick={() => this.setState({ scarfColor: item.name })}></div>
                                        )}
                                    </div>
                                    <p className="text">
                                        UNIFORME
                                    </p>
                                    <br />
                                    <div className="color6">
                                        {uniformColors.map((item) =>
                                            <div className="color" style={{ backgroundColor: item.hex }} onClick={() => this.setState({ uniformColor: item.name })}></div>
                                        )}
                                    </div>
                                </div>
                            }



                        </div>

                    </Content>
                </Modal>
            </>
        )
    }
}

export default withRouter(inject('usersStore', 'teamsStore')(observer(AvatarModal)));