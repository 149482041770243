import Phaser from "phaser";
/**
 * Clase para iniciar la primera escena del juego
 * @author Mehdi Lahbis Mhamdi
 * @class
 */

export class StartScene extends Phaser.Scene {
    /**
     * Constructor Escena Inicial
     */
    constructor() {
        super({
            key: 'startScene'
        });
    }

    /**
     * Método para asignar valores iniciales a las propiedades de la escena.
     * <br>
     * Se ejecuta antes del método create.
     */
    init() {
        this.cameras.main.fadeIn(500);
    }

    /**
    * Método para crear todos los elementos de la escena.
    */
    create() {
        // imagen de fondo
        const bg = this.add.image(1000, 510, 'bg');
        // título
        const title = this.add.text(592, 135, 'Atrapa la mosca',
        { align: 'center', fontStyle: 'Bold', fontSize: 104, fontFamily: "Quicksand"});
        // texto para iniciar
        const text = this.add.text(835, 752, '¡Haz click para jugar!',
        { align: 'center', fontSize: 34, fontFamily: "Quicksand"});
        // botón de iniciar
        const startbtn = this.add.image(1000, 510, 'startbtn').setScale(0.4).setInteractive({ cursor: 'pointer' });
        // animación del botón
        this.add.tween({
            targets: startbtn,
            scale: 0.35,
            duration: 1500,
            yoyo: true,
            repeat: -1
        })
        // evento al pulsar el botón
        startbtn.on('pointerdown', () => {
            this.scene.start('firstLevel')
        });
    }
}