import React from "react";

import { Modal, Button, Row, Col } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

//Para poder utilizar 'this.props.history.push()' en un componente
import { withRouter } from 'react-router-dom';
import { observer } from "mobx-react";


import GlobalFonts from "../assets/fonts/fonts";

import './EndGameModal.scss';

import { reaction } from "mobx";


class EndGameModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            title: '',
            icon: null,
        };

        this.reactions = [];
    }

    //*********************************************************
    componentDidMount() {

        this.reactions.push(
            reaction(
                () => this.props.show,
                () => {
                    if (this.props.show == true) {
                        this.setState({ show: this.props.show })
                    }
                }
            )
        )
    }

    //*********************************************************
    render() {
        console.log(this.props.user.role)
        return (
            <>
                <GlobalFonts />
                <Modal
                    className="endgamemodal"
                    title={
                        <Row justify='center' align="top">
                            <Col className="title-background" sm={{ span: 16 }} md={{ span: 16 }}>
                                <div className="title">
                                    {"FIN DE JUEGO"}
                                </div>
                            </Col>
                        </Row>}
                    closeIcon=
                    {
                        <Button onClick={this.props.close} shape="circle" id="close-button" >
                            <CloseCircleOutlined className="close-circle" />
                        </Button>
                    }
                    open={this.props.show}
                    width="max-content"
                    closable={true}
                    maskClosable={true}
                    footer={null}
                >
                    <div className="content-modal">
                        <>
                            <p className="message">¡Enhorabuena! {this.props.user.role.id == 0 ? 'Has' : 'Habéis'} conseguido recuperar el ecosistema del río. Para ver el estado inicial y final del río pulsa en el botón que está en la parte derecha inferior de la pantalla.</p>
                        </>
                    </div>
                </Modal>
            </>
        )
    }
}

export default withRouter((observer(EndGameModal)));