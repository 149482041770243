import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

// Vistas de la aplicacion
import Login from "./views/Login.js";
import Main from "./views/Main.js";
import Activation from './views/Activation.js';
import ChangePassword from './views/ChangePassword.js';
import Memorik from "./components/games/memorik/MyGame.js";
import Palabra from "./components/games/palabra_revuelta/MyGame.js";
import Rana from "./components/games/atrapa_la_mosca/MyGame.js";
import Explora from "./components/games/explora_tu_rio/MyGame.js";
//import Conexion from "./components/games/conexion_fluvial/MyGame.js";
import ManagersLogin from "./views/ManagersLogin.js";


// ATENCION! Para evitar errores 404 cuando se refresca una página en ReactJS es necesario redirigir todo el tráfico a la página de index, para lo cual en Tomcat se debe incluir un archivo "public/WEB-INF/web.xml" con la configuracion apropiada

// ATENCION! Esta forma de pasar los props a cada página asegura poder acceder a this.props.location y this.props.history, la alternativa sería usar withRouter en cada uno de los componentes como por ejemplo en AppHeader.js

// ATENCIÓN! Para configurar el basename de la aplicación (es decir, poder desplegarla en un subdirectorio) se debe de cambiar el basename aquí del BrowserRouter, pero también añadir el parámetro homepage en el package.json

class Routing extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>          
          <Route path="/" exact render={props => <Login {...props} />} />
          <Route path="/main" exact render={props => <Main {...props} />} />
          <Route path="/managers-login" exact render={props => <ManagersLogin {...props} />} />
          <Route path="/activation" exact render={props => <Activation {...props} />}/>
          <Route path="/recovery" exact render={props => <ChangePassword {...props} />} />
          <Route path="/memorik" exact render={props => <Memorik {...props} />} />
          <Route path="/palabrarevuelta" exact render={props => <Palabra {...props} />} />
          <Route path="/atrapa-la-mosca" exact render={props => <Rana {...props} />} />
          <Route path="/explora-tu-rio" exact render={props => <Explora {...props} />} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default Routing;