
//****************************  FLAGS FOR USERSSTORE  *****************************
export const LOGIN = "LOGIN";
export const REMINDUSER = "REMINDUSER";
export const REGISTER_USER = "REGISTER_USER";
export const USER_SAVE_DATA = "USER_SAVE_DATA";
export const CHANGE_USER_DATA = "CHANGE_USER_DATA";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_PAGINATED_LIST_OF_USERS = "GET_PAGINATED_LIST_OF_USERS";
export const GET_LIST_OF_USERS = "GET_LIST_OF_USERS";
export const USER_REMOVE = "USER_REMOVE";
export const GET_USER_DATA = "GET_USER_DATA";

//****************************  FLAGS FOR ORGANIZATIONSSTORE  *****************************
export const GET_PAGINATED_LIST_OF_ORGANIZATIONS = "GET_PAGINATED_LIST_OF_ORGANIZATIONS"; 
export const ORGANIZATION_SAVE_DATA = "ORGANIZATION_SAVE_DATA";
export const ORGANIZATION_REMOVE = "ORGANIZATION_REMOVE";
export const GET_LIST_OF_ORGANIZATIONS = "GET_LIST_OF_ORGANIZATIONS"; 

//****************************  FLAGS FOR TEAMSSTORE  *******************************
export const GET_PAGINATED_LIST_OF_TEAMS = "GET_PAGINATED_LIST_OF_TEAMS";
export const TEAM_SAVE_DATA = "TEAM_SAVE_DATA";
export const GET_LIST_OF_TEAMS = "GET_LIST_OF_TEAMS";
export const TEAM_REMOVE = "TEAM_REMOVE";
export const GET_LIST_OF_TEAMS_BY_ORGANIZATION = "GET_LIST_OF_TEAMS_BY_ORGANIZATION";
export const GET_RANKING_USER_BY_TEAM = "GET_RANKING_USER_BY_TEAM"; 
export const CHANGE_STEP = "CHANGE_STEP"; 
export const GET_TEAM_BY_PLAYER = "GET_TEAM_BY_PLAYER";
export const TEAM_GET_AVATAR = "TEAM_GET_AVATAR";

//****************************  FLAGS FOR PLAYERSSTORE  *******************************
export const LOGIN_TEAM_PLAYER = "LOGIN_TEAM_PLAYER";
export const GET_PAGINATED_LIST_OF_TEAM_PLAYERS = "GET_PAGINATED_LIST_OF_TEAM_PLAYERS";
export const TEAM_PLAYER_SAVE_DATA = "TEAM_PLAYER_SAVE_DATA";
export const MULTI_TEAM_PLAYER_SAVE_DATA = "MULTI_TEAM_PLAYER_SAVE_DATA";
export const GET_LIST_OF_TEAM_PLAYERS = "GET_LIST_OF_TEAM_PLAYERS";
export const TEAM_PLAYER_REMOVE = "TEAM_PLAYER_REMOVE";
export const PLAYER_SAVE_SCORE = "USER_SAVE_SCORE";


//****************************  FLASG FOR INDIVIDUAL PLAYERS  *******************************
export const LOGIN_PLAYER = "LOGIN_PLAYER";
export const GET_PAGINATED_LIST_OF_PLAYERS = "GET_PAGINATED_LIST_OF_PLAYERS";
export const PLAYER_SAVE_DATA = "PLAYER_SAVE_DATA";
export const GET_LIST_OF_PLAYERS = "GET_LIST_OF_PLAYERS";
export const PLAYER_REMOVE = "PLAYER_REMOVE";


