import Phaser from "phaser";
/**
 * Clase para crear el Segundo Nivel del juego
 * @author Mehdi Lahbis Mhamdi
 * @class
 * @property {Array} elements - Array con las palabras y respuestas del nivel.
 * @property {Array} flies - Array con los objetos 'mosca' creados.
 * @property {number} radius - Radio de distancia entre moscas y rana.
 * @property {boolean} rotate - Para controlar la rotación de la rana.
 * @property {boolean} canMove - Para controlar la interacción del usuario/a.
 * @property {number} attempts - Número de intentos. 
 * @property {boolean} passedLevel - Flag para saber si se pasó de nivel o no.
 */
export class SecondLevel extends Phaser.Scene {
    elements = [
        { id: 1, question: "Ríos", answers: ['ecosistemas', 'canales', 'solo agua', 'salados'], correctAnswer: 'ecosistemas' },
        { id: 2, question: "Llanura de ", answers: ['inundación', 'río', 'valle', 'construcción'], correctAnswer: 'inundación' },
        { id: 3, question: "Bosque de ", answers: ['ribera', 'inundación', 'río', 'acuático'], correctAnswer: 'ribera' },
        { id: 4, question: "Ecosistema ", answers: ['fluvial', 'de ribera', 'de río', 'animal'], correctAnswer: 'fluvial' },
        { id: 5, question: "Organismos vivos", answers: ['bióticos', 'abióticos', 'roca', 'sedimento'], correctAnswer: 'bióticos' },
        { id: 6, question: "Ecosistema acuático", answers: ['charca', 'pradera', 'bosque', 'desierto'], correctAnswer: 'charca' }
    ];

    initialLength = this.elements.length;

    flies = [];

    correctFlies = [];

    radius = 450;

    score = 0;

    rotate = true;

    canMove = false;

    attempts = 0;

    correctAnswer = 0;

    passedLevel = false;

    correctAnswers = ''

    /**
    * Constructor Nivel 2
    */
    constructor() {
        super({
            key: 'secondLevel'
        });
    }

    /**
     * Método para asignar valores iniciales a las propiedades de la escena.
     * <br>
     * Se ejecuta antes del método create.
     */
    init() {
        this.cameras.main.fadeIn(500);
        this.shuffle()
        this.attempts = 3;
        this.rotate = true;
    }

    /**
     * Se baraja el array que contiene las palabras del nivel
     */
    shuffle() {
        var rnd = new Phaser.Math.RandomDataGenerator();

        this.element = this.elements.length > 0 ? rnd.pick(this.elements) : "";
    }

    /**
    * Método para crear los elementos de la escena
    */
    create(data) {
        // imagen de fondo
        const bg = this.add.image(this.game.scale.width / 2, this.game.scale.height / 2, 'bg');

        this.score = data.score;

        const title = this.add.text(0, -100, "Los ríos",
            { align: 'center', fontStyle: 'bold', fontSize: 45, fontFamily: "Quicksand", color: '#000' });

        title.x = 1100 - title.width / 2;

        const titleContainer = this.add.container(0, 0);

        // creamos el fondo que se va a adaptar a cualquier palabra
        const textWidth = title.width; // ancho de la palabra
        const textHeight = title.height; // alto de la palabra

        // creamos el objeto gráfico para la pastilla de fondo
        const padding = 25; // Espacio adicional alrededor del texto
        const textBackground = this.add.graphics();
        textBackground.fillStyle(0xd2f9ff, 1); // Color blanco, opacidad 90%

        // dibujar un rectángulo redondeado como fondo
        textBackground.fillRoundedRect(
            title.x - padding / 2, // X posición (centrada)
            title.y - padding / 2, // Y posición (centrada)
            textWidth + padding, // Ancho del rectángulo
            textHeight + padding, // Altura del rectángulo
            25 // Radio de las esquinas redondeadas
        );

        // asegurarse de que el fondo esté detrás del texto
        textBackground.setDepth(-1);

        // añadimos al contenedor el texto y su fondo
        titleContainer.add([textBackground, title]);

        this.add.tween({
            targets: titleContainer,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            delay: 500,
            y: 140
        });

        // icono puzzle y su animación (de derecha a izquierda)
        const puzzle = this.add.image(10000, 100, 'icon');
        puzzle.setScale(0.02);

        this.add.tween({
            targets: puzzle,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            x: this.scale.width - 100
        });

        // nombre de tanda y su animación (de izquierda a derecha)
        const series = this.add.text(-200, 50, 'NIVEL 2',
            { align: 'center', fontStyle: "bold", fontSize: 45, fontFamily: "Quicksand", color: '#F39C12' }).setDepth(1);

        this.add.tween({
            targets: series,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            x: 50
        });
        // fondo blanco para el nombre de la tanda
        this.add.image(90, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(140, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(190, 75, 'textBg').setTintFill(0xFFFFFF);

        // fondo blanco para la puntuación
        this.add.image(350, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(400, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(450, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(500, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(550, 75, 'textBg').setTintFill(0xFFFFFF);

        // puntuación y su animación
        this.scoreText = this.add.text(-500, 60,
            "PUNTUACIÓN|  " + this.score,
            { align: "top", fontStyle: "bold", fontSize: 30, fontFamily: 'Quicksand', color: "#F39C12" }
        ).setDepth(1);

        this.add.tween({
            targets: this.scoreText,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            delay: 500,
            x: 305
        });

        // creamos las gotas (vidas) y se las asignamos a la variable this.waterDrops
        this.waterDrops = this.createWaterDrops();

        // añadimos la imagen de la rana (interactiva, con animación, en el centro de la pantalla y disminuimos su tamaño)
        this.frog = this.add.image(1100, 550, 'frog').setInteractive()
            .setScale(2);

        this.add.tween({
            targets: this.frog,
            scale: 0.7,
            duration: 1200,
            ease: Phaser.Math.Easing.In
        });

        // añadimos la imagen de la rana con lengua (interactiva, con animación, en el centro de la pantalla y disminuimos su tamaño)
        this.frogTongue = this.add.image(this.frog.x, this.frog.y, 'frog-tongue')
            .setScale(0.7).setVisible(false);

        this.correctAnswers = this.add.text(0, 250, "Aciertos: " + this.correctAnswer + " de " + this.initialLength,
            { align: 'center', fontStyle: "bold", fontSize: 35, fontFamily: "Quicksand", color: '#fff' }
        );
        this.correctAnswers.x = 250 - this.correctAnswers.width / 2;

        for (let i = 0; i < this.elements.length; i++) {
            var fly = this.add.image(250, 90 * i + 370, 'fly').setScale(0.4).setAlpha(0.35);
            var flyContainer = this.add.container(0, 0);

            // creamos el fondo que se va a adaptar a cualquier palabra
            const textWidth = fly.displayWidth; // ancho de la palabra
            const textHeight = fly.displayHeight; // alto de la palabra

            // creamos el objeto gráfico para la pastilla de fondo
            const padding = 25; // Espacio adicional alrededor del texto
            const background = this.add.graphics();
            background.fillStyle(0xFFFFFF, 1); // Color blanco, opacidad 90%

            // dibujar un rectángulo redondeado como fondo
            background.fillRoundedRect(
                fly.x - textWidth / 2 - padding / 2, // X posición (centrada)
                fly.y - textHeight / 2 - padding / 2, // Y posición (centrada)
                textWidth + padding, // Ancho del rectángulo
                textHeight + padding + 10, // Altura del rectángulo
                10 // Radio de las esquinas redondeadas
            );

            // asegurarse de que el fondo esté detrás del texto
            background.setDepth(-1);

            // añadimos al contenedor la palabra y su fondo
            flyContainer.add([background, fly]);
            this.correctFlies.push(fly);
        }

        // llamada al método que da comienzo al juego
        this.startGame();
    }

    /**
    * Método de actualización que se ejecuta durando toda la vida del juego.
    * Mientras la variable 'rotate' sea true, la imagen de rana y la de rana con lengua giran continuamente al compás
    * Si no quedan intentos, llamamos al método 'gameOver'
    * Si 'passedLevel' es true, llamamos al método 'nextLevel'
    * <br>
    */
    update() {
        this.scoreText.setText('PUNTUACIÓN |  ' + this.score);

        if (this.rotate) {
            this.frog.rotation += 0.01;
            this.frogTongue.rotation += 0.01;
        }

        if (this.attempts == 0) {
            this.gameOver();
            this.attempts -= 1;
        }

        if (this.passedLevel) {
            this.nextLevel();
            this.passedLevel = false;
        }

        this.correctAnswers.setText("Aciertos: " + this.correctAnswer + " de " + this.initialLength);//+ this.elements.length);
    }

    /**
    * Método para crear tantas gotas (y su fondo) como intentos queremos que tenga el usuario.
    * <br>
    * attempts = 3 
    * @returns {waterDrop}
    */
    createWaterDrops() {
        return Array.from(new Array(this.attempts)).map((el, index) => {
            const waterDrop = this.add.image(-1000, 170, 'drop')
                .setScale(0.5).setDepth(1);

            const waterDropBox = this.add.image(80 + 30 * index, 170, 'textBg').setTintFill(0xFFFFFF).setScale(0.8);

            this.add.tween({
                targets: [waterDrop],
                ease: Phaser.Math.Easing.Expo.InOut,
                duration: 1000,
                delay: 500 + index * 200,
                x: 70 + 40 * index // marginLeft + spaceBetween * index
            });

            waterDrop.box = waterDropBox;

            return waterDrop;
        });
    }

    /**
    * Método para crear las moscas de cada palabra.
    * @returns {fly} 
    */
    createFlies() {
        const centerX = this.frog.x;//this.game.scale.width / 2;
        const centerY = this.frog.y;//this.game.scale.height / 2;

        this.angle = Phaser.Math.DegToRad(90);
        const deg45 = Phaser.Math.DegToRad(45);

        const flies = Phaser.Utils.Array.Shuffle([...this.element?.answers]);

        return flies.map((item, index) => {
            var x = centerX + 2000 * Math.cos(deg45 + this.angle * index);
            var y = centerY + 2000 * Math.sin(deg45 + this.angle * index);

            const fly = this.add.sprite(x + 0, y + 0, 'fly').setScale(0.5).setInteractive({ cursor: 'pointer' })//.setOrigin(0.5, 0.5);
            fly.answer = item;

            const anguloHaciaCentro = Phaser.Math.Angle.Between(x, y, centerX, centerY);
            fly.rotation = anguloHaciaCentro + Phaser.Math.DegToRad(90);

            fly.text = this.add.text(0, 0, fly.answer.toUpperCase()).setOrigin(0.5, 0.5);
            fly.text.setStyle({ fontFamily: 'Arial', fontSize: '32px', color: '#000000' });
            const textWidth = fly.text.width;
            const textHeight = fly.text.height;

            // Crear el objeto gráfico para la pastilla de fondo
            const padding = 20; // Espacio adicional alrededor del texto
            const background = this.add.graphics();
            background.fillStyle(0xFFFFFF, 0.5); // Color azul, opacidad 100%

            // Dibujar un rectángulo redondeado como fondo
            background.fillRoundedRect(
                fly.text.x - textWidth / 2 - padding / 2, // X posición (centrada)
                fly.text.y - textHeight / 2 - padding / 2, // Y posición (centrada)
                textWidth + padding, // Ancho del rectángulo
                textHeight + padding, // Altura del rectángulo
                20 // Radio de las esquinas redondeadas
            );

            // Asegurarse de que el fondo esté detrás del texto
            background.setDepth(-1);

            fly.textContainer = this.add.container(x, y, [background, fly.text]);
            fly.setDepth(1);

            fly.textContainer.x = fly.x;
            fly.textContainer.y = fly.y + fly.height / 2 + 5;

            // animación para que se muevan ligeramente las moscas simulando que están volando
            this.add.tween({
                targets: [fly],
                x: centerX + this.radius * Math.cos(deg45 + this.angle * index),
                y: centerY + this.radius * Math.sin(deg45 + this.angle * index),
                duration: 3000,
                onComplete: () => {
                    this.canMove = true;
                    this.tweens.add({
                        targets: fly,
                        x: fly.x + (25 * Math.random() - 0.7), // destination x position
                        y: fly.y + (25 * Math.random() - 0.5), // destination y position
                        duration: 1000, // duration in milliseconds
                        ease: "Linear", // easing function
                        repeat: -1, // number of times to repeat the tween
                        yoyo: true, // whether to reverse the tween
                    });
                }
            });

            // animación para incorporar las moscas al inicio del juego
            this.add.tween({
                targets: fly.textContainer,
                x: centerX + this.radius * Math.cos(deg45 + this.angle * index),
                y: (centerY + this.radius * Math.sin(deg45 + this.angle * index)) + fly.height / 2 + 5,
                ease: "Linear",
                duration: 3000
            });

            return fly;
        })
    }

    /**
    * Método para reiniciar el juego.
    * <br>
    */
    restartGame() {
        this.cameras.main.fadeOut(200 * this.flies.length);
        this.flies = [];
        this.time.addEvent({
            delay: 200 * this.flies.length,
            callback: () => {
                this.flies = [];
                this.canMove = false;
                this.rotation = false;
                this.scene.restart();
            }
        })
    }

    /**
    * Método que inicia el juego.
    * <br>
    */
    startGame() {
        // si existe una palabra con su fondo la eliminamos
        this.frogContainer?.destroy();
        // contenedor en el que se va a añadir la palabra de turno con su fondo
        this.frogContainer = this.add.container(1100, this.game.scale.height / 2);
        // texto de la palabra de turno
        this.centerText = this.add.text(0, 0, this.element.question?.toUpperCase())
            .setStyle({ fontFamily: 'Arial', fontSize: '32px', color: '#FF8000' })
            .setOrigin(0.5, 0.5);

        // creamos el fondo que se va a adaptar a cualquier palabra
        const textWidth = this.centerText.width; // ancho de la palabra
        const textHeight = this.centerText.height; // alto de la palabra

        // creamos el objeto gráfico para la pastilla de fondo
        const padding = 20; // Espacio adicional alrededor del texto
        const background = this.add.graphics();
        background.fillStyle(0xFFFFFF, 0.9); // Color blanco, opacidad 90%

        // dibujar un rectángulo redondeado como fondo
        background.fillRoundedRect(
            this.centerText.x - textWidth / 2 - padding / 2, // X posición (centrada)
            this.centerText.y - textHeight / 2 - padding / 2, // Y posición (centrada)
            textWidth + padding, // Ancho del rectángulo
            textHeight + padding, // Altura del rectángulo
            20 // Radio de las esquinas redondeadas
        );

        // asegurarse de que el fondo esté detrás del texto
        background.setDepth(-1);

        // añadimos al contenedor la palabra y su fondo
        this.frogContainer.add([background, this.centerText]);
        this.frogContainer.y = 680; // asignamos la altura

        // creamos las moscas
        this.flies = this.createFlies();

        // lógica del juego
        this.flies.forEach((item, el) => {
            item.on('pointerdown', () => {
                if (this.canMove) {
                    // al pulsar en cada respuesta (mosca) la rana giraría de tal forma que queda en frente de ésta
                    this.tweens.add({
                        targets: [this.frog, this.frogTongue],
                        angle: item.angle > 180 ? item.angle - 180 : item.angle + 180,
                        duration: 1500,
                        repeat: 0,
                        // mientras se ejecuta la animación, no permitimos que pueda haber otras respuestas
                        onUpdate: () => {
                            this.canMove = false;
                        },
                        // cuando se completa el giro, hacemos que la rana con lengua se visualice y que la mosca se acerque 
                        // (simulando que la rana 'caza' la mosca)
                        onComplete: () => {
                            this.frogTongue.setVisible(true);
                            this.frog.setVisible(false);
                            this.rotate = false;
                            this.tweens.add({
                                targets: [item],
                                x: this.frog.x,
                                y: this.frog.y,
                                duration: 1000,
                                repeat: 0,
                                onComplete: () => {
                                    this.frog.setVisible(true);
                                    this.frogTongue.setVisible(false);
                                    this.rotate = true;
                                    this.check(item);
                                }
                            });
                        }
                    });
                }
            });
        });
    }

    /**
     * Método para comprobar la respuesta y ver si se ha completado el nivel
     * <br>
     * Si le quedan intentos puede continuar, sino puede volver a reiniciar el nivel
     * @param {Object} item // el objeto mosca
     */
    check(item) {
        // creamos icono de acierto
        const correctAnswer = this.add.image(1100, 400, 'ok').setOrigin(.5)
            .setDepth(3)
            .setInteractive()
            .setScale(0);

        // creamos icono de respuesta incorrecta
        const wrongAnswer = this.add.image(1100, 400, 'x').setOrigin(.5)
            .setDepth(3)
            .setInteractive()
            .setScale(0);

        // no permitimos al usuario interactuar mientras se comprueba
        this.canMove = false;
        // caso de acierto
        if (item.answer == this.element.correctAnswer) {
            item.destroy();
            const firstFly = this.correctFlies[0];
            this.add.tween({
                targets: firstFly,
                duration: 500,
                delay: 500,
                alpha: 2,
                onComplete: () => {
                    this.correctFlies = this.correctFlies.filter(item => item != this.correctFlies[0]);
                }
            });
            // aminación para mostrar el icono de tick verde 
            this.add.tween({
                targets: correctAnswer,
                scale: 0.1,
                duration: 1200,
                ease: Phaser.Math.Easing.In,
                // al completarse, tras 2000 milisegundos (2 segundos) hacemos desaparecer el icono
                onComplete: () => {
                    setTimeout(() => {
                        correctAnswer.destroy();
                    }, 2000);
                }
            });

            // eliminamos la palabra correcta de la lista
            this.elements = this.elements.filter(item => item.id != this.element.id);

            this.correctAnswer += 1;
            const score = this.add.text(530, 110, '+10',
                { align: 'center', fontStyle: "bold", fontSize: 22, fontFamily: "Quicksand", color: '#fff' });

            // animación de la puntuación obtenida
            this.add.tween({
                targets: score,
                x: 530,
                y: 60,
                duration: 1500,
                onComplete: () => {
                    this.score += 10;
                }
            });

            // si no quedan palabras, pasamos de nivel
            if (this.elements.length <= 0) {
                this.passedLevel = true;
            }
            // sino, destruimos las respuestas y moscas de la palabra acertada
            else {
                setTimeout(() => {
                    this.flies.map(item => {
                        item.destroy();
                        item.textContainer.destroy();
                    });
                    // si quedan palabras en este nivel volvemos a llamar al método que inicia el juego
                    if (this.elements.length > 0) {
                        this.startGame();
                    }
                    // habilitamos el juego de nuevo
                    this.canMove = true;
                }, 2000);
            }
            // barajamos las palabras
            this.shuffle();
        }
        // caso de error 
        else {
            // perdemos un intento (una gota)
            const lastwaterDrop = this.waterDrops[this.waterDrops.length - 1];
            this.add.tween({
                targets: lastwaterDrop,
                duration: 1000,
                alpha: 0.2,
                onComplete: () => {
                    this.waterDrops.pop();
                }
            });
            // hacemos aparecer el icono de respuesta incorrecta
            this.add.tween({
                targets: wrongAnswer,
                scale: 0.2,
                duration: 1000,
                ease: Phaser.Math.Easing.In,
                // paramos el giro de la rana
                onUpdate: () => {
                    this.rotate = false;
                },
            });

            // tras 2 segundos, habilitamos el juego de nuevo y hacemos que la rana siga girando 
            setTimeout(() => {
                wrongAnswer.destroy();
                if (this.attempts > 0) {
                    this.rotate = true;
                    this.canMove = true;
                }
            }, 2000);

            // decrementar las oportunidades en 1 unidad
            this.attempts -= 1;
        }
    }

    /**
    * Método para acceder al siguiente nivel.
    * <br>
    */
    nextLevel() {
        // creamos los elementos que componen el cuadro para pulsar y pasar de nivel
        const winnerContainer = this.add.container(0, 0);

        const nextLevel = this.add.image(1800, 900, 'next').setAlpha(0.5);

        const nextBox1 = this.add.image(1790, 970, 'textBg').setTintFill(0xFFFFFF);
        const nextBox2 = this.add.image(1840, 970, 'textBg').setTintFill(0xFFFFFF);
        const nextBox3 = this.add.image(1890, 970, 'textBg').setTintFill(0xFFFFFF);

        const nextBtn = this.add.image(1845, 870, 'nextbtn').setScale(0.1).setInteractive({ cursor: 'pointer' });

        const nextLevelText = this.add.text(1755, 960, 'Siguiente nivel',
            { align: 'center', fontStyle: "bold", fontSize: 22, fontFamily: "Quicksand", color: '#F39C12' })
            .setDepth(1)
            .setInteractive({ cursor: 'pointer' });

        const winnerText = this.add.text(1840, 780, "¡Muy bien!",
            { align: "center", fontStyle: "bold", fontSize: 40, fontFamily: "Quicksand", color: "#fff" }
        ).setOrigin(.5)
            .setDepth(3);

        winnerContainer.add(nextLevel);
        winnerContainer.add(nextBtn);

        winnerContainer.add(winnerText);
        winnerContainer.add(nextBox1);
        winnerContainer.add(nextBox2);
        winnerContainer.add(nextBox3);
        winnerContainer.add(nextLevelText);

        winnerContainer.y = 1000;
        //winnerContainer.x = -1650;

        this.add.tween({
            targets: winnerContainer,
            duration: 1500,
            ease: Phaser.Math.Easing.Out,
            y: 0,
        });
        this.canMove = false;
        this.rotate = false;

        // al pulsar hacemos desaparecer los elementos de la pantalla 
        nextLevelText.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.flies.map(item => this.add.tween({
                targets: [item, item.textContainer],
                duration: 2000,
                delay: 1000 + 200 * item,
                y: 2000,
            }));

            this.add.tween({
                targets: [this.frog, this.frogTongue, this.flies, this.frogContainer, this.waterDrops, winnerContainer],
                duration: 2000,
                ease: Phaser.Math.Easing.Bounce.Out,
                y: 2000,
                // al completarse la animación iniciamos el siguiente nivel
                onComplete: () => {
                    this.scene.start('thirdLevel', { score: this.score });
                }
            })
        });
        // mismo comportamiento que en el caso anterior
        nextBtn.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.flies.map(item => this.add.tween({
                targets: [item, item.textContainer],
                duration: 2000,
                delay: 1000 + 200 * item,
                y: 2000,
            }));

            this.add.tween({
                targets: [this.frog, this.flies, this.frogContainer, this.waterDrops, winnerContainer],
                duration: 2000,
                ease: Phaser.Math.Easing.Bounce.Out,
                y: 2000,
                // al completarse la animación iniciamos el siguiente nivel
                onComplete: () => {
                    this.scene.start('thirdLevel', { score: this.score });
                }
            })
        });
    }

    /**
    * Método que se ejecuta al quedarse sin intento y para reiniciar el nivel.
    * <br>
    */
    gameOver() {
        // no permitimos que se pueda seguir jugando y hacemos q la rana deje de girar
        this.canMove = false;
        this.rotate = false;

        // contenedor con todos los elementos del cuadro de volver a intentarlo
        const gameOverContainer = this.add.container(0, 0);
        const restartLevel = this.add.image(1800, 900, 'next').setAlpha(0.5);

        const restartBox1 = this.add.image(1785, 970, 'textBg').setTintFill(0xFFFFFF);
        const restartBox2 = this.add.image(1840, 970, 'textBg').setTintFill(0xFFFFFF);
        const restartBox3 = this.add.image(1895, 970, 'textBg').setTintFill(0xFFFFFF);

        const restartBtn = this.add.image(1845, 870, 'restart').setScale(0.1).setInteractive({ cursor: 'pointer' });

        const restartLevelText = this.add.text(1740, 960, 'Vuelve a intentarlo',
            { align: 'center', fontStyle: "bold", fontSize: 22, fontFamily: "Quicksand", color: '#F39C12' })
            .setDepth(1)
            .setInteractive({ cursor: 'pointer' })

        const restartText = this.add.text(1840, 780, "¡Ups!",
            { align: "center", fontStyle: "bold", fontSize: 40, fontFamily: "Quicksand", color: "#fff" }
        ).setOrigin(.5)
            .setDepth(3);

        gameOverContainer.add(restartLevel);
        gameOverContainer.add(restartBtn);

        gameOverContainer.add(restartText);
        gameOverContainer.add(restartBox1);
        gameOverContainer.add(restartBox2);
        gameOverContainer.add(restartBox3);
        gameOverContainer.add(restartLevelText);

        gameOverContainer.y = 1000;

        this.add.tween({
            targets: gameOverContainer,
            duration: 1500,
            ease: Phaser.Math.Easing.Out,
            y: 0
        });

        // moscas, rana y textos más transparentes 
        this.flies.map(fly => {
            fly.setAlpha(0.4);
            fly.textContainer.setAlpha(0.4);
        });
        this.frogContainer.setAlpha(0.4);
        this.frogTongue.setAlpha(0.4);
        this.frog.setAlpha(0.4);

        // al pulsar hacemos desaparecer los elementos de la pantalla 
        restartBtn.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.flies.map(item => this.add.tween({
                targets: [item, item.textContainer],
                duration: 2000,
                delay: 1000 + 200 * item,
                y: 2000,
            }));

            this.add.tween({
                targets: [this.flies, this.flies.textContainer, this.frog, this.frogContainer,
                this.frogTongue, gameOverContainer],
                duration: 2000,
                ease: Phaser.Math.Easing.Bounce.Out,
                y: 2000,
                // al completarse, reiniciamos la escena (nivel)
                onComplete: () => {
                    this.restartGame();
                }
            })
        });

        // mismo comportamiento que en el caso anterior
        restartLevelText.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.flies.map(item => this.add.tween({
                targets: [item, item.textContainer],
                duration: 2000,
                delay: 1000 + 200 * item,
                y: 2000,
            }));

            this.add.tween({
                targets: [this.flies, this.flies.textContainer, this.frog, this.frogContainer,
                this.frogTongue, gameOverContainer],
                duration: 2000,
                ease: Phaser.Math.Easing.Bounce.Out,
                y: 2000,
                // al completarse, reiniciamos la escena (nivel)
                onComplete: () => {
                    this.restartGame();
                }
            })
        });
    }
}