import React from 'react';
import ReactDOM from 'react-dom/client';

// Librería para soporte de MobX
import { configure } from "mobx";
import { Provider } from 'mobx-react';

//import Login from './components/Login.js';
//import 'antd/dist/reset.css';
import './index.scss';
import Routing from './Routing.js';
import AuthenticationService from './services/AuthenticationService';

import rootStore from "./stores";

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

console.log("REACTJS ENVIRONMENT: " + process.env.NODE_ENV);

//import App from './App';

AuthenticationService.init();

// Parámetros de configuración de MobX
configure({
  enforceActions: "never",
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: false,
  disableErrorBoundaries: false
});

// ATENCION! No se pueden configurar aquí los parámetros globales de persistencia de mobx-persist-store con configurePersistable
// Esto es debido a que para que funcionen debería hacerse antes de la creación de cualquier store y es complicado hacerlo, así que mejor se declaran en cada store específicamente

//------------------------------------------------------------------------------------

// ATENCIÓN!!! La configuración de los diferentes documentos mostrados se hace en el archivo publico "config.js" que se carga aquí
// Este método permite cambiar parámetros de configuración si necesidad de hacer un build de la aplicación ReactJS de nuevo

const script = document.createElement("script");
script.src = process.env.REACT_APP_CONFIG_FILE;
script.async = true;
document.body.appendChild(script);

script.onload = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <Provider {...rootStore}>
      <Routing/>
    </Provider>
  );
}


