import React from "react";

import "./Login.scss";
import "../fonts.scss";
import { Layout, Row, Col, Form, Input, Button, Switch, message } from 'antd';
import { LockOutlined, SyncOutlined, MailOutlined } from '@ant-design/icons';
import { observer, inject } from "mobx-react";
import { reaction } from 'mobx';
import cookie from 'react-cookies';

import * as Actions from '../stores/actionFlags.js';

import AuthenticationService from "../services/AuthenticationService.js";

import logoApp from "../assets/images/app-logo.png";

const { Content } = Layout;

class ManagersLogin extends React.Component {

    //*********************************************************
    constructor(props) {
        super(props);

        this.rememberMe = false;

        this.state = {
            showPasswordForm: false,
            showRemindUser: false,
            team: null,
            user: null,
            username: null
        }

        this.reactions = [];
        this.username = null;
        this.idTeam = 0;
    }

    //*********************************************************
    componentDidMount() {
        this.handleLoadTeamsList();

        this.reactions.push(
            reaction(
                () => this.props.usersStore.loggedIn,
                () => {
                    if (this.props.usersStore.loggedIn) {
                        this.props.history.push("/main?" + this.idTeam);
                    }
                }
            ),
            reaction(
                () => this.props.usersStore.userLogged,
                () => {
                    if (this.props.usersStore.userLogged.resetPassword) {
                        this.props.history.push('/recovery');
                    }
                }
            ),
            reaction(
                () => this.props.generalStore.error || this.props.usersStore.sentUserEmailFlag,
                () => {
                    const sentUserEmailFlag = this.props.usersStore.sentUserEmailFlag;
                    const errorMessage = this.props.generalStore.error;

                    if (errorMessage != undefined && errorMessage.length > 0) {

                        // Ignorar los errores de tipo 401 que pueden provenir de otros componentes cuando se intentan entrar directamente a una URL no autorizada tecleándola
                        if (errorMessage.indexOf("401") <= 0) message.error(errorMessage);
                        this.props.generalStore.doActionRemoveGeneralError();
                    }
                    else if (sentUserEmailFlag) {
                        message.success("Se han enviado los nuevos datos de acceso al correo electrónico del usuario ");
                        this.setState({ showRemindUser: false });
                    }
                }
            ),
            reaction(
                () => this.props.generalStore.error || this.props.usersStore.sentUserActivationFlag,
                () => {
                    const sentUserActivationFlag = this.props.usersStore.sentUserActivationFlag;
                    const errorMessage = this.props.generalStore.error;

                    if (errorMessage != undefined && errorMessage.length > 0) {

                        // Ignorar los errores de tipo 401 que pueden provenir de otros componentes cuando se intentan entrar directamente a una URL no autorizada tecleándola
                        if (errorMessage.indexOf("401") <= 0) message.error(errorMessage);
                        this.props.generalStore.doActionRemoveGeneralError();
                    }
                    else if (sentUserActivationFlag) {
                        message.success("Se ha enviado un correo eléctronico a la dirección introducida para la activación de su cuenta");
                        this.setState({ showRegisterUser: false });
                    }
                }
            )
        );

        if (!AuthenticationService.isUserLoggedIn()) {
            let refreshToken = cookie.load(process.env.REACT_APP_COOKIE_REFRESHTOKEN);

            if (this.props.location.search.indexOf("&srrtk") >= 0) {
                refreshToken = this.props.location.search.substring(this.props.location.search.indexOf("&srrtk") + 7);
            }

            if (refreshToken != null && refreshToken.length > 0) {
                this.props.usersStore.doActionRememberMe(refreshToken);
            }
        }
    }

    //*********************************************************
    handleLoadTeamsList = () => {
        if (this.props.teamsStore != undefined) {
            this.props.teamsStore.doActionGetTeamsList();
        }
    }

    //*********************************************************
    handleLoadUserData = (id) => {
        if (this.props.usersStore != undefined) {
            this.props.usersStore.doActionGetUserData(id);
        }
    }

    //*********************************************************
    componentWillUnmount() {
        this.reactions.forEach((dispose) => dispose());
    }

    //*********************************************************
    handleRemindUserSubmit = (values) => {
        this.props.usersStore.doActionRemindUser(values.email);
    }

    //*********************************************************
    handleRememberMeChange = (checked) => {
        this.rememberMe = checked;
    }

    //*********************************************************
    handleManagerLoginSubmit = (values) => {
        this.props.usersStore.doActionPlayerLogin(this.username, values.password, this.team, this.rememberMe.toString());
    }

    //*********************************************************
    render() {
        const isFetchingUser = this.props.generalStore.processing.includes(Actions.LOGIN);
        const isSendingUserEmail = this.props.generalStore.processing.includes(Actions.REMINDUSER);

        const teams = this.props.teamsStore.teams == undefined ? [] : this.props.teamsStore.teams.list;

        const location = window.location.href;
        this.idTeam = location?.split('?')[1]?.split('#')[1];

        const team = teams?.find(item => item.id == this.idTeam);
        const username = team?.managerUser.username;
        const name = team?.managerUser.name;

        this.username = username;

        return (
            <>
                <Layout className="login-layout">
                    <Content>
                        <div>
                            <Row justify="center" align="top">
                                <Col className="center-content" xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 20 }}>
                                    <img className="logo-app" src={logoApp} alt="Logo de Gusarapos"></img>
                                    <h2 className="app-subtitle">JUEGA Y MEJORA TU ECOSISTEMA</h2>
                                </Col>
                            </Row>
                            <Row justify="center" align="center" className={this.state.showPasswordForm || this.state.showRemindUser ? 'invisible' : 'visible'}>
                                <Row className="first-form">
                                    <Col className="option">
                                        <Button type="primary" className="login-form-button" onClick={() => this.setState({ showPasswordForm: true })}>Bienvenido/a {name}</Button>
                                        <div className='description' justify='center' align='center'>
                                            <p className="text">Pulsa aquí para introducir tu contraseña y acceder al área de juego</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Row>

                            <Row justify="center" align="bottom" className={this.state.showPasswordForm ? "visible" : "invisible"}>
                                <Col xs={{ span: 18 }} sm={{ span: 12 }} xl={{ span: 8 }}>
                                    <Form className="login-form" onFinish={this.handleManagerLoginSubmit}>

                                        {/*  <Form.Item name="username" defaultValue={user_name} hidden={true} >
                                    </Form.Item> */}
                                        <Form.Item className="form-field" name="password" autoComplete="false" rules={[{ required: true, message: 'Introduce tu contraseña' }]}>
                                            <Input.Password prefix={<LockOutlined className="login-form-icon" />} type="password" placeholder="Contraseña" />
                                        </Form.Item>

                                        {/* <Form.Item className="form-field rememberme" >
                                            <span className="text">Recordarme</span>
                                            <Form.Item name="rememberme" valuePropName="checked" noStyle>
                                                <Switch onChange={this.handleRememberMeChange} checkedChildren="si" unCheckedChildren="no" />
                                            </Form.Item>
                                        </Form.Item> */}

                                        <Form.Item >
                                            {!isFetchingUser && <Button type="primary" htmlType="submit" className="login-form-button"><label className="label_buttom">ENTRAR</label></Button>}
                                            {isFetchingUser && <Button type="primary" disabled style={{ backgroundColor: `#AAAAAA` }} className="login-form-button"><SyncOutlined spin /></Button>}
                                        </Form.Item>
                                    </Form>
                                    <Row justify="center" align="bottom">
                                        <Button type="link" className="bottom-link" onClick={() => this.setState({ showRemindUser: true, showPasswordForm: false })} ><div class="label_question"> ¿Has olvidado tu contraseña?</div></Button>
                                    </Row>
                                    <Row justify="center" align="bottom">
                                        <Button type="link" className="bottom-link" onClick={() => this.setState({ showPasswordForm: false })} ><div class="label_question">Volver a la pantalla de entrada</div></Button>
                                    </Row>
                                </Col>
                            </Row>

                            <Row justify="center" align="bottom" className={this.state.showRemindUser ? "visible" : "invisible"}>
                                <Col xs={{ span: 18 }} sm={{ span: 12 }} xl={{ span: 8 }}>

                                    <Form className="reminduser-form" initialValues={{ remember: true }} onFinish={this.handleRemindUserSubmit} >
                                        <Form.Item className="form-field" name="email" rules={[{ required: true, type: 'email', message: 'Introduce un correo electrónico válido' }]} >
                                            <Input prefix={<MailOutlined className="reminduser-form-icon" />} placeholder="Correo electrónico" />
                                        </Form.Item>

                                        <Form.Item>
                                            {!isSendingUserEmail && <Button type="primary" htmlType="submit" className="remind-form-button"><label className="label_buttom">RECORDAR ACCESO</label></Button>}
                                            {isSendingUserEmail && <Button type="primary" disabled style={{ backgroundColor: `#AAAAAA` }} className="remind-form-button"><SyncOutlined spin /></Button>}
                                        </Form.Item>
                                    </Form>
                                    <Row justify="center" align="bottom">
                                        <Button type="link" className="bottom-link" onClick={() => this.setState({ showRemindUser: false })} ><div class="label_question">Volver a la pantalla de entrada</div></Button>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Content>
                </Layout>
            </>
        )
    }
}

export default inject('usersStore', 'generalStore', 'organizationsStore', 'teamsStore')(observer(ManagersLogin));