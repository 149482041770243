import React from "react";



import { EditOutlined, EyeInvisibleOutlined, EyeOutlined, IdcardOutlined, PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Popconfirm, Button, Col, Empty, Form, Input, InputNumber, List, Menu, message, Modal, Row, Select, Spin, Switch } from "antd";
import { Content } from "antd/es/layout/layout.js";
//Para poder utilizar 'this.props.history.push()' en un componente
import { withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";


import GlobalFonts from "../assets/fonts/fonts";

import './EditorPlayerModal.scss';

import { reaction } from "mobx";


class EditorPlayerlModal extends React.Component {

    constructor(props) {
        super(props);
        this.editPlayerForm = React.createRef();
        this.state = {
            showEditor: true,
            selectedPlayer: null,
            show: false,
            title: '',
            icon: null,
        };

        this.reactions = [];
    }
    //*********************************************************
    componentDidMount() {
        this.reactions.push(
            reaction(
                () => this.props.show,
                () => {
                    if (this.props.show == true) {
                        this.setState({ show: this.props.show })
                    }
                }
            )
        )
    }
    //*********************************************************
    handleSaveEdit = (event) => {
        event.stopPropagation("handleSaveEdit");
        if (this.editPlayerForm != null)
            this.editPlayerForm.current.submit();
    }
    //*********************************************************
    handleCancelEdit = () => {
        this.setState({ showEditor: true, selectedPlayer: null });
    }

    //*********************************************************
    handlePlayerEditorSubmit = async (values) => {
        var id = this.props.user.id;
        values.id = id;
        setTimeout(async () => {
            try {
                // Espera a que `doActionSavePlayerEdit` termine y devuelva el usuario actualizado
                const updatedUser = await this.props.playersStore.doActionSavePlayerEdit(values);
                // Ahora actualiza el usuario en sesión

                this.props.usersStore.updateUserInSession(updatedUser);
            } catch (error) {
                console.error("Error al guardar los datos del jugador:", error);
            }
        }, 1000);
        this.props.usersStore.doActionLogout();
    }
    //*********************************************************  

    render() {
        const userLogged = this.props.user;

        var selectedPlayerData = {
            "username": userLogged.username.split("#")[0],
            "idTeam": userLogged.team.id,
            "teamName": userLogged.team.name,
            "password": ""

        }
        return (
            <>
                <GlobalFonts />

                <Modal
                    className="player-editor"
                    title={
                        <Row justify='center' align="top">
                            <Col className="title-background" sm={{ span: 16 }} md={{ span: 16 }}>
                                <div className="title">
                                    {"EDITAR JUGADOR/A"}
                                </div>
                            </Col>
                        </Row>}
                    closeIcon=
                    {
                        <Button onClick={this.props.close} shape="circle" id="close-button" >
                            <CloseCircleOutlined className="close-circle" />
                        </Button>
                    }
                    footer={[
                        // <Button key="back" onClick={this.props.close}>
                        //     Cerrar
                        // </Button>,                       
                        <Popconfirm onClick={(e) => e.stopPropagation()} placement="topRight" title="Al grabar los cambios, la sesión se cerrará. ¿Quiere grabar los cambios igualmente?" onCancel={(e) => e.stopPropagation()} onConfirm={(event) => { this.handleSaveEdit(event) }} okText="Si" cancelText="No">
                            <Button key="submit" type="primary">Grabar datos</Button>
                        </Popconfirm>
                        // <Button key="submit" type="primary" onClick={this.handleSaveEdit}>
                        //     Grabar datos
                        // </Button>
                    ]}
                    open={this.props.show}
                    width="max-content"
                    closable={true}
                    maskClosable={true}
                >
                    <Form ref={this.editPlayerForm} initialValues={selectedPlayerData} onFinish={this.handlePlayerEditorSubmit} layout="vertical">
                        <Content className="player-profile">
                            <Row justify="center" align="top">
                                <Col className="player-profile-data" xs={{ span: 24 }}>
                                    {userLogged.role.name == ("PLAYER") &&
                                        <Form.Item name="username" label="Alias del jugador/a" rules={[{ required: !this.state.selectedPlayer, message: "Introduce el alias del jugador/a" }]}>
                                            <Input
                                                placeholder="Usuario/a"
                                                bordered={true}
                                                suffix={<EditOutlined key="edit" />}
                                            />
                                        </Form.Item>
                                    }

                                    <Form.Item name="teamName" label="Equipo" rules={[{ required: !this.state.selectedPlayer }]}>
                                        <Input
                                            disabled
                                        />
                                    </Form.Item>

                                    <Form.Item name="idTeam" label="idTeam" rules={[{ required: !this.state.selectedPlayer }]} hidden={true}>
                                        <Input
                                            disabled
                                        />
                                    </Form.Item>

                                    <Form.Item  name="password" label="Contraseña" rules={[{ required: false, message: 'Introduce la nueva contraseña' }]}>
                                        <Input.Password
                                            placeholder="Contraseña"
                                            bordered={true}
                                            iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                                            autoComplete="new-password"
                                        />
                                    </Form.Item>

                                    <Form.Item className="form-field" name="passwordRepeat" dependencies={['password']}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (getFieldValue('password') != '' && (value === undefined || getFieldValue('password') != value)) {
                                                        return Promise.reject(new Error('Las dos contraseñas introducidas no coinciden'));
                                                    }
                                                    return Promise.resolve();

                                                },
                                            })]}>
                                        <Input.Password
                                            placeholder="Repetir contraseña"
                                            bordered={true}
                                            iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                                            autoComplete="new-password"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Content>
                    </Form>
                </Modal>

            </>
        )
    }
}

export default withRouter(inject('playersStore', 'usersStore')(observer(EditorPlayerlModal)));