import React from 'react';
import Phaser from 'phaser';

import { inject, observer } from 'mobx-react';

import { Preloader } from './scenes/preloader';
import { StartScene } from './scenes/startScene';
import { FirstLevel } from './scenes/firstLevel';
import { SecondLevel } from './scenes/secondLevel';
import { ThirdLevel } from './scenes/thirdLevel';
import { FourthLevel } from './scenes/fourthLevel';
import { Button, Col, Row } from 'antd';
import '../MyGame.scss';
import { Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import exit from '../../../assets/images/buttons/IconoSalir-PG.png';
import info from '../../../assets/images/buttons/IconoAviso_Legal-PG.png';
import { Space } from 'antd';

class MyGame extends React.Component {
    constructor(props) {
        super(props);
        this.game = {
            title: ''
        };
        this.state = {
            showInfo: false,
            alt: '',
            isHovered: false
        }
    }

    //*********************************************************
    componentDidMount() {
        this.handleLoadTeamsData();

        const config = {
            width: 2000,
            height: 1020,
            title: 'Palabra Revuelta Game',
            parent: 'phaser-game',
            type: Phaser.AUTO,
            scale: {
                mode: Phaser.Scale.FIT,
                autoCenter: Phaser.Scale.CENTER_BOTH
            },

            scene: [Preloader, StartScene, FirstLevel, SecondLevel, ThirdLevel, FourthLevel],
        };

        this.game = new Phaser.Game(config);
    }

    //*********************************************************
    handleLoadTeamsData = () => {
        if (this.props.teamsStore != undefined) {
            this.props.teamsStore.doActionGetTeamsList();
        }
    }
    
    //*********************************************************
    closeWindow = () => {
        let data = {}
        let player = this.props.usersStore.userLogged;
        let score = this.game.score;
        let passed = this.game.passed;
        let initialScore = this.game.initialScore;       

        if (passed && score > 0) {
            data.id = player.id;
            data.score = score;
            this.props.usersStore.doActionSaveScore(data);
        } else if (!passed && score > 0) {
            data.id = player.id;
            data.score = initialScore;
            this.props.usersStore.doActionSaveScore(data);
        }
        setTimeout(() => {
            this.game.destroy();
            window.history.back();
        }, 1000);
    }

    //*********************************************************
    render() {
        return (
            <>
                <Space direction="vertical" className="iconsVertical-game" >
                    <div className='image-container' onClick={() => this.closeWindow()} onMouseEnter={() => this.setState({ alt: "Cerrar", isHovered: true })}
                        onMouseLeave={() => this.setState({ isHovered: false })}>
                            <img src={exit} className="buttonIconsConfig"/>
                            {this.state.isHovered && <div className="alt-text">{this.state.alt}</div>}
                    </div>
                    <div className='image-container' onClick={() => this.setState({ showInfo: true })} onMouseEnter={() => this.setState({ alt: "Instrucciones", isHovered: true })}
                        onMouseLeave={() => this.setState({ isHovered: false })}>
                            <img src={info} className="buttonIconsConfig"/>
                            {this.state.isHovered && <div className="alt-text">{this.state.alt}</div>}
                    </div>               
                </Space>
                {this.state.showInfo &&
                    <Modal
                        className="manualmodal"
                        title={
                            <Row justify='center' align="top">
                                <Col className="title-background" sm={{ span: 16 }} md={{ span: 16 }}>
                                    <div className="title">
                                        Instrucciones
                                    </div>
                                </Col>
                            </Row>}
                        closeIcon=
                        {
                            <Button onClick={() => this.setState({ showInfo: false })} shape="circle" id="close-button" >
                                <CloseCircleOutlined className="close-circle" />
                            </Button>
                        }
                        open={this.state.showInfo}
                        width="max-content"
                        bodyStyle={{ overflowY: 'scroll' }}
                        closable={true}
                        maskClosable={true}
                        footer={null}
                    >
                        <div className="content-manual">
                            <>  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi rhoncus eu risus non consequat. Praesent vestibulum eros justo, eget vestibulum felis cursus varius. Fusce volutpat aliquam justo eu laoreet. Phasellus vel hendrerit lectus. Aliquam sagittis orci nisl. Curabitur metus mi, condimentum in dolor at, bibendum blandit libero. Vivamus vel hendrerit ex. Praesent ultricies urna in iaculis dictum. Cras nec venenatis risus, et varius nibh. Proin pulvinar finibus urna, id iaculis ex tristique tempor. Quisque eleifend lacinia enim, nec mattis tellus ultrices eu. Proin porttitor diam neque.</p>
                                <p>Vivamus justo odio, ultrices id tempor non, ultrices a mauris. Vivamus sodales nunc ut odio faucibus condimentum. Donec eget accumsan ex. Suspendisse vel neque sem. Cras ac lacus tempus, auctor nisl in, viverra sapien. Nam id mi sit amet libero volutpat porttitor. Integer sit amet ipsum arcu.</p>
                                <p>Vestibulum feugiat aliquet laoreet. Donec semper volutpat ipsum nec malesuada. Ut ut nibh mauris. Quisque sodales mi eget nulla vestibulum, vitae pretium tellus rhoncus. Morbi accumsan fringilla purus eget commodo. Quisque ultricies, sem blandit tincidunt euismod, tortor lorem ultrices augue, vitae auctor justo nibh ut nibh. Fusce malesuada, ligula a rhoncus commodo, purus elit ultricies justo, non mollis tortor dui eu tellus. Sed non hendrerit elit. In hac habitasse platea dictumst. Mauris non nunc id leo eleifend tempor ut ac ligula. Donec magna odio, dapibus quis risus pellentesque, vehicula ornare purus. Proin eget tortor porttitor, semper massa nec, condimentum tortor.</p>
                                <p>In mollis ante id magna mollis vulputate. Mauris sit amet massa felis. Mauris pulvinar congue lacus, a congue dolor finibus eget. Donec iaculis risus lacus, at suscipit risus porttitor ac. Curabitur eu lectus id nunc sollicitudin mollis sed et justo. Aenean mauris nisl, bibendum at mauris mattis, venenatis auctor arcu. In ex justo, vulputate nec viverra et, euismod nec nibh. Phasellus pretium felis laoreet orci iaculis, a porttitor magna aliquet. Vivamus convallis diam fermentum, scelerisque enim et, vestibulum purus. Vestibulum sit amet ante nisi. Phasellus nisi ex, vestibulum quis imperdiet vitae, pellentesque eu turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                <p>Sed vel nibh quis purus gravida suscipit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aenean efficitur faucibus velit, a vestibulum turpis maximus non. Donec gravida, turpis sit amet egestas facilisis, sem ex dictum libero, at sollicitudin urna tellus in sem. Donec placerat arcu eget dolor mollis, ac pharetra velit ultrices. Cras ut rhoncus ex. Etiam ornare tortor eget ipsum ornare, in posuere enim rhoncus. Vestibulum laoreet, est vel vulputate rhoncus, sem elit malesuada ex, ac maximus tortor erat sed diam. Praesent sed lectus vel justo sodales eleifend in at quam. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse euismod enim in neque ullamcorper tempor at non ipsum. Aenean eleifend lacus id odio porta, non posuere libero scelerisque. Cras sapien libero, ornare ut maximus non, consectetur volutpat sem. Curabitur vel nulla odio.</p>
                            </>
                        </div>
                    </Modal>}
                <div id='phaser-game' className='game'></div>
            </>)
    }
}

export default inject('teamsStore', 'organizationsStore', 'generalStore', 'usersStore')(observer(MyGame));