import React from "react";

import { Button, Row, Col } from "antd";

//Para poder utilizar 'this.props.history.push()' en un componente
import { withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";

import GlobalFonts from "../assets/fonts/fonts";
import logoEducantabrico from "../assets/images/logo-educantabrico.png";


import '../views/Main.scss';
import './ThreatComponent.scss'
import { reaction } from "mobx";
import { message } from "antd";

class ThreatComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showMoreInfo: false
        };

        this.reactions = [];
    }

    //*********************************************************
    componentDidMount() {
        this.handleLoadSteps();

        this.reactions.push(
            reaction(
                () => this.props.teamsStore.steps,
                () => {
                    if (this.props.teamsStore.steps == []) {
                        this.handleLoadSteps();
                    }
                }
            )
        )
    }

    //*********************************************************
    handleLoadSteps() {
        this.props.teamsStore.doActionGetThreatsList();
    }

    //*********************************************************
    handleChangeBackground(step) {

        if (step.price <= this.props.team.score) {
            var idTeam = this.props.team.id;
            var idStep = step.id;
            this.props.teamsStore.doActionChangeTeamsStep(idTeam, idStep);
            window.location.reload();
        } else {
            message.error("Tu equipo no tiene suficientes puntos para adquirir esta mejora. ¡Debéis jugar a los minijuegos para obtener más puntos!")
        }
    }

    //*********************************************************
    toggleInfo() {
        this.setState({ showMoreInfo: !this.state.showMoreInfo });
    }

    //*********************************************************
    render() {
        const threatsList = this.props.teamsStore.threats == undefined ? [] : this.props.teamsStore.threats;

        const nextStep = this.props.step;

        const threat = threatsList?.find(item => item.step.id == nextStep.id);

        if (threat != undefined) {
            let infor = threat.info.replaceAll("[*]", "\n");
        }

        return (
            <>
                <GlobalFonts />
                <div className={`${!this.state.showMoreInfo ? 'threatComponent' : 'threatComponent threatComponent_more_height'}`}>
                    <Row justify='center' align="top">
                        <Col>
                            <div className="threat-level">
                                <p>
                                    NIVEL {nextStep?.level.num}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row justify='center' align="top">
                        <Col>
                            <div className="threat-title-level">
                                <p>
                                    {nextStep?.level.title}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row justify='center' align="top">
                        <Col>
                            <div className="threat">
                                <div className="threat-title-threat">
                                    <p className="text">
                                        {threat?.title.toUpperCase()}
                                    </p>

                                </div>
                                <div className="threat-subtitle">
                                    <p >
                                        {threat?.subtitle.toUpperCase()}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row justify='center' align="top">
                        <Col className="information_col">
                            {!this.state.showMoreInfo && (<Button className="threat-button" onClick={() => this.toggleInfo()}>{'Saber más'}</Button>)}

                            {this.state.showMoreInfo && (
                                <div className="text-threat">
                                    <p>
                                        {threat.info.split('[*]').map((paragraph) => {
                                            return <span>
                                                {paragraph.split('&').map((element, index) => {
                                                    if (index != 0 && index % 2 != 0) {
                                                        return <span className="text-orange">{element}</span>;
                                                    }
                                                    else {
                                                        return <span>{element}</span>
                                                    }
                                                })}
                                                <br />
                                                <br />
                                            </span>
                                        })}
                                    </p>
                                    <p>Completa tu aprendizaje en</p>
                                    {<a href="https://www.educantabrico.es/" target="_blank" rel="noopener noreferrer">
                                        <img src={logoEducantabrico} alt="Logo Educantabrico" />
                                    </a>}</div>
                            )}

                            {this.state.showMoreInfo && (<Button className="threat-button" onClick={() => this.toggleInfo()}>{'Cerrar'}</Button>)}
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default withRouter(inject('teamsStore', 'generalStore')(observer(ThreatComponent)));