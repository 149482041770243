import Phaser from 'phaser';
import configApp from '../../../../configApp.js';
/**
 * Clase para cargar los elementos del juego
 * @author Mehdi Lahbis Mhamdi
 * @class
 */
export class Preloader extends Phaser.Scene {
    constructor() {
        super({
            key: 'Preloader'
        });
    }

    preload() {
        // fondo
        this.load.image('bg', configApp.getParams().PLAY_URL+'game/atrapa_la_mosca/assets/bg.png');
        // botón start
        this.load.image('startbtn', configApp.getParams().PLAY_URL+'game/atrapa_la_mosca/assets/btnStart.png');
        // icono puzzle y linea
        this.load.image('icon', configApp.getParams().PLAY_URL+'game/atrapa_la_mosca/assets/icono.png');
        this.load.image('line', configApp.getParams().PLAY_URL+'game/atrapa_la_mosca/assets/linea.png');
        // gota
        this.load.image('drop', configApp.getParams().PLAY_URL+'game/atrapa_la_mosca/assets/waterDrop.png');
        // rana
        this.load.image('frog', configApp.getParams().PLAY_URL+'game/atrapa_la_mosca/assets/frog.png');
        // rana con lengua
        this.load.image('frog-tongue', configApp.getParams().PLAY_URL+'game/atrapa_la_mosca/assets/frog-tongue.png');
        // mosca
        this.load.image('fly', configApp.getParams().PLAY_URL+'game/atrapa_la_mosca/assets/fly.png');
        // textBg
        this.load.image('textBg', configApp.getParams().PLAY_URL+'game/atrapa_la_mosca/assets/textBg.png');
        // ok
        this.load.image('ok', configApp.getParams().PLAY_URL+'game/atrapa_la_mosca/assets/ok.png');
        // wrong answer
        this.load.image('x', configApp.getParams().PLAY_URL+'game/atrapa_la_mosca/assets/x.png');
        // icono volver a intentar
        this.load.image('restart', configApp.getParams().PLAY_URL+'game/atrapa_la_mosca/assets/tryAgainBtn.png');
        //cuadro y boton next
        this.load.image('nextbtn', configApp.getParams().PLAY_URL+'game/atrapa_la_mosca/assets/btnNext.png');
        this.load.image('next', configApp.getParams().PLAY_URL+'game/atrapa_la_mosca/assets/cuadroNext.png');
    }

    create() {
        this.scene.start('startScene');
    }
}