import Phaser from "phaser";

/**
 * Clase para crear el Primer Nivel del juego.
 * @author Mehdi Lahbis Mhamdi
 * @class
 * @property {Array} sprites - Letras de la palabra.
 * @property {Array} circles - Círculos (huecos) donde va la respuesta.
 * @property {Array} chars - Caracteres de la palabra.
 * @property {number} completed - Número de letras completadas en cada momento.
 * @property {number} completedText - Número para calcular el progreso de los aciertos.
 * <br>
 * Se utilizará para dar valor a la opacidad de la imagen central.
 * @property {JSONObject} circlesGridConfiguration - Configuración del posicionamiento de los círculos.
 * @property {JSONObject} lettersGridConfiguration - Configuración del posicionamiento de las letras.
 * @property {boolean} canMove - Propiedad para controlar cuándo puede o no hacer click el/la jugador/a.
 */

export class FirstLevel extends Phaser.Scene {
    elements = [
        { word: "Cauce", clue: "¡No pienses que no me muevo! En ocasiones, cambio con el paso de los años.", image: "cauce" },
        { word: "Bosque de ribera", clue: "Este tipo de bosque acoge muchas especies de flora y fauna, y es fundamental para el funcionamiento de nuestros ríos.", image: "bosque" },
        { word: "Llanura de inundación", clue: "Es el espacio a cada lado del río que se inunda en épocas de precipitaciones elevadas.", image: "llanura" },
        { word: "Acuífero", clue: "En ellos el agua puede estar durante muchos años antes de salir a la superficie, ¡a veces hasta 500 años!", image: "acuifero" },
        { word: "Gusarapo", clue: "Nuestro pequeño amigo lleva un traje de piedras para proteger su delicado cuerpo y camuflarse.", image: "gusarapo" },
        { word: "Ecosistema fluvial", clue: "Son uno de los ecosistemas más amenazados. ¡Entre todos tenemos que protegerlos, ya que son vitales para la supervivencia del planeta!", image: "ecosistema" },
        { word: "Cascada", clue: "Como los ríos tienen que salvar tanto desnivel en este tramo, puedes ver estas espectaculares formaciones.", image: "cascada" },
        { word: "Meandro", clue: "Estas formas tan peculiares que hacen los ríos se deben a que el río erosiona una de sus orillas y deposita materiales en la otra.", image: "meandro" },
        { word: "Anguila", clue: "Este animal vive en los ríos y solo sale de él para ir a reproducirse en un sitio muy lejano, en el mar de los Sargazos.", image: "anguila" },
        { word: "Desembocadura", clue: "La zona final del río, su tramo bajo.", image: "desembocadura" }
    ];

    sprites = [];

    circles = [];

    completed = 0;

    score = 0;

    chars = [];

    completedText = 0;

    canMove = false;

    passed = false;

    circlesGridConfiguration = {
        x: 80,
        y: 800,
        paddingX: 72,
        paddingY: 50
    };

    lettersGridConfiguration = {
        x: 1565,
        y: 425,
        paddingX: 72,
        paddingY: 55
    };

    /**
     * Constructor Tanda 1
     */
    constructor() {
        super({
            key: 'firstLevel'
        });
    }

    /**
    * Método para asignar valores iniciales a las propiedades de la escena.
    * <br>
    * Se ejecuta antes del método create.
    */
    init() {
        this.cameras.main.fadeIn(500);
        this.shuffle();
        this.attempts = 3;
    }

    /**
     * Método para barajar las palabras.
     */
    shuffle() {
        var rnd = new Phaser.Math.RandomDataGenerator();

        this.element = this.elements.length > 0 ? rnd.pick(this.elements) : "";
    }

    /**
    * Método para crear todos los elementos de la escena.
    */
    create() {
        // imagen de fondo
        const bg = this.add.image(1000, 510, 'bg');

        // título y su animación (de arriba a abajo)
        const title = this.add.text(775, -1000, "La palabra revuelta", {
            align: 'center', fontSize: 45, fontFamily: "Quicksand"
        });

        this.add.tween({
            targets: title,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            y: 20
        });

        // icono puzzle y su animación (de derecha a izquierda)
        const puzzle = this.add.image(10000, 70, 'icon');
        puzzle.setScale(0.02);

        this.add.tween({
            targets: puzzle,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            x: this.scale.width - 100
        });

        // nombre de tanda y su animación (de izquierda a derecha)
        const series = this.add.text(-200, 50, 'NIVEL 1',
            { align: 'center', fontStyle: "bold", fontSize: 45, fontFamily: "Quicksand", color: '#F39C12' }).setDepth(1);

        this.add.tween({
            targets: series,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            x: 50
        });
        // fondo blanco para el nombre de la tanda
        this.add.image(90, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(140, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(190, 75, 'textBg').setTintFill(0xFFFFFF);

        // fondo blanco para la puntuación
        this.add.image(350, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(400, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(450, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(500, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(550, 75, 'textBg').setTintFill(0xFFFFFF);

        // puntuación y su animación
        this.scoreText = this.add.text(-500, 60,
            "PUNTUACIÓN|  " + this.score,
            { align: "top", fontStyle: "bold", fontSize: 30, fontFamily: 'Quicksand', color: "#F39C12" }
        ).setDepth(1);

        this.add.tween({
            targets: this.scoreText,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            delay: 500,
            x: 305
        });

        // icono de ? y su animación (de abajo a arriba)
        this.qIcon = this.add.image(this.scale.width / 2, 8000, 'questionIcon').setScale(0.1);

        this.tweens.add({
            targets: this.qIcon,
            duration: 2000,
            delay: 2000,
            y: 400
        });

        // cuadro de letras
        const lettersBag = this.add.image(this.scale.width - 280, 450, 'lettersBag')
        lettersBag.setScale(1.62);

        // cuadro de pista/curiosidad
        this.cluebox = this.add.image(270, 450, 'clueBag').setScale(1.63);

        // creamos las gotas (vidas) y se las asignamos a la variable this.waterDrops
        this.waterDrops = this.createWaterDrops();

        this.game.events.emit("POINTS", this.score);
        this.game.events.emit("PASSED LEVEL", this.passed);

        // llamada al método que da comienzo al juego
        this.startGame();
    }

    /**
    * Método para actualizar valores de las propiedades de ciertos elementos.
    * <br>
    * En este caso, la opacidad del icono '?' y de la imagen central según se van acertando las letras.
    */ 
    update() {
        this.scoreText.setText('PUNTUACIÓN |  ' + this.score);
        this.completedText = (this.completed / this.element.word?.replaceAll(' ', '').length);
        this.centerImage.setAlpha(this.completedText);
        this.qIcon.setAlpha(1 - this.completedText);

        if (this.circles.every(item => item.busy)) this.centerImage.setAlpha(1);

        this.game.score = this.score;
        this.game.passed = this.passed;
    }
    
    /**
    * Método para crear tantas gotas (y su fondo) como intentos queremos que tenga el usuario.
    * <br>
    * attempts = 3 
    * @returns {waterDrop}
    */
    createWaterDrops() {
        return Array.from(new Array(this.attempts)).map((el, index) => {
            const waterDrop = this.add.image(-1000, 150, "drop")
                .setScale(0.5).setDepth(1);

            const waterDropBox = this.add.image(100 + 40 * index, 150, 'textBg').setTintFill(0xFFFFFF).setScale(0.8);

            this.add.tween({
                targets: [waterDrop],
                ease: Phaser.Math.Easing.Expo.InOut,
                duration: 1000,
                delay: 500 + index * 200,
                x: 90 + 50 * index // marginLeft + spaceBetween * index
            });

            waterDrop.box = waterDropBox;

            return waterDrop;
        });
    }

    /**
    * Método para reiniciar la escena (tanda) actual.
    * <br>
    * Se reestablecen los elementos a su valor inicial.
    */
    restartGame() {
        this.cameras.main.fadeOut(200 * this.sprites.length);
        this.sprites = [];
        this.circles = [];
        this.time.addEvent({
            delay: 200 * this.sprites.length,
            callback: () => {
                this.sprites = [];
                this.circles = [];
                this.canMove = false;
                this.score = 0;
                this.completed = 0;
                this.scene.restart();
            }
        })
    }

    /**
     * Método que inicia el juego para palabras simples.
     * <br>
     * Se crean los elementos en este apartado ya que dependen de la palabra que toca.
     */
    startGame() {
        // si existe texto o imagen cargado lo destruimos para que no se cargue 
        // el nuevo texto o imagen encima del anterior
        this.clueText?.destroy();
        this.centerImage?.destroy();

        // se crea el texto 
        this.clueText = this.add.text(-8000, 0, this.element.clue,
            { fontSize: 22, fontFamily: 'Geologica', color: "#fff", wordWrap: { width: 420 } });
        this.clueText.setAlign('center');

        // para centrar el texto en el cuadro de pista/curiosidad
        var height = this.clueText.displayHeight;
        var width = this.clueText.displayWidth;
        this.tweens.add({
            targets: this.clueText,
            duration: 1000,
            delay: 1000,
            x: this.cluebox.x - width / 2,
            y: this.cluebox.y - height / 2 - 5
        });

        // se crea la imagen
        this.centerImage = this.add.image(995, -1250, this.element.image).setDepth(1).setScale(1.5);
        this.tweens.add({
            targets: this.centerImage,
            duration: 1000,
            delay: 2000,
            y: 400
        });

        // en caso de palabras compuestas, llamamos al método 'handleLongWords()'
        if (this.element.word?.includes(' ')) {
            this.handleLongWords();
        }
        else {
            this.sprites = [];
            this.circles = [];
            this.characters = this.element.word?.split('');

            // ponemos todas las letras en mayuscula ya que estan cargadas en minuscula
            this.characters = this.characters.map(item => item.toLowerCase())
            // ignoramos espacios en blanco 
            this.characters = this.characters.filter(item => item != ' ')
            // ignoramos tildes
            //this.characters = this.characters.map(item => item.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));

            for (let i = 0; i < this.characters.length; i++) {
                if (this.characters[i] != ' ') {
                    let char = this.add.sprite(10000, -100, this.characters[i]).setOrigin(0.5).setInteractive({ cursor: 'pointer' }).setDepth(1);
                    char.setScale(0.4).setDepth(1);

                    // todos los sprites tienen tamaño 50x50
                    char.displayHeight = 50;
                    char.displayWidth = 50;

                    // se crean los círculos (huecos) donde irán las letras
                    let circle = this.add.image(1000, 10000, ' ').setDepth(0.5);
                    circle.setTintFill(0xffffff);
                    circle.busy = false;

                    this.circles.push(circle);
                    this.sprites.push(char);
                }
            }

            // desordenar las letras 
            this.sprites = this.sprites.sort(() => Math.random() - 0.5);

            // animaciones
            this.sprites.map((sprite, index) => {
                sprite.y = this.lettersGridConfiguration.y - 100 + (15 + this.lettersGridConfiguration.paddingY) * Math.floor(index / 5)
                this.tweens.add({
                    targets: sprite,
                    duration: 800,
                    delay: 1000 + index * 100,
                    x: this.lettersGridConfiguration.x + (5 + this.lettersGridConfiguration.paddingX) * (index % 5)
                })
            });

            this.circles.map((circle, index) => {
                this.add.tween({
                    targets: circle,
                    duration: 1000,
                    delay: 2000 + index * 100,
                    y: (this.circlesGridConfiguration.y /*+ 150*/) + (10 + this.circlesGridConfiguration.paddingY) * Math.floor(index / 14),
                    onComplete: () => {
                        this.canMove = true;
                    }
                })
            });

            // centrar elementos
            let totalWidth = 0;
            for (let i = 0; i < this.circles.length; i++) {
                totalWidth += this.circles[i].width - 600;
            }

            let centerX = this.game.config.width / 2;
            let firstcircleX = centerX - totalWidth / 2;

            let currentX = firstcircleX + 30;

            for (let i = 0; i < this.circles.length; i++) {
                this.circles[i].x = currentX;
                currentX += this.circles[i].width - 600;
            }

            // llamada al método que gestiona la lógica del juego
            this.gameLogic();
        }
    }

    /**
     * Mismo método que 'startGame()' para palabras compuestas.
     */
    handleLongWords() {
        // cada token es una palabra de la palabra compuesta
        const tokens = this.element.word.split(" ");
        this.characters = [];
        this.circlesRows = [];
        this.chars = [];
        this.sprites = [];
        this.circles = [];

        for (let i = 0; i < tokens.length; i++) {
            this.chars = tokens[i].split('');
            this.chars = this.chars.map(item => item.toLowerCase())
            //this.chars = this.chars.map(item => item.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
            this.chars.map(item => this.characters.push(item))

            this.chars = this.chars.sort(() => Math.random() - 0.5);

            let circlesRow = [];
            for (let r = 0; r < this.chars.length; r++) {
                let character = this.add.sprite(8000, -1000, this.chars[r]).setOrigin(0.5).setInteractive({ cursor: 'pointer' });
                let circle = this.add.image(-10000, 1500, ' ').setDepth(0.5);
                circle.setTintFill(0xffffff);
                circle.busy = false;

                this.circles.push(circle);
                circlesRow.push(circle);
                this.sprites.push(character);
                character.displayHeight = 50;
                character.displayWidth = 50;
                circle.row = i;
                circle.rowLength = this.chars.length;
            }

            this.circlesRows.push(circlesRow);

            this.sprites.map((sprite, index) => {
                sprite.y = this.lettersGridConfiguration.y - 100 + (10 + this.lettersGridConfiguration.paddingY) * Math.floor(index / 5)
                this.tweens.add({
                    targets: sprite,
                    duration: 800,
                    delay: 1000 + index * 100,
                    x: this.lettersGridConfiguration.x + (5 + this.lettersGridConfiguration.paddingX) * (index % 5)
                });
            });
        }

        // separar por renglones los circulos correspondientes a cada palabra
        for (let i = 0; i < this.circles.length; i++) {
            this.tweens.add({
                targets: this.circles[i],
                duration: 1000,
                delay: 2000 + i * 100,
                y: this.circlesGridConfiguration.y - 100 + (15 + this.circlesGridConfiguration.paddingY) * Math.floor(this.circles[i].row),
                onComplete: () => {
                    this.canMove = true;
                }
            });
        }

        // Centrar circles 
        for (let i = 0; i < this.circlesRows.length; i++) {
            let totalWidth = 0;
            let centerX = this.game.config.width / 2;
            for (let index = 0; index < this.circlesRows[i].length; index++) {
                totalWidth += this.circles[index].width - 600;

                let firstcircleX = centerX - totalWidth / 2 + 30;

                let currentX = firstcircleX;

                this.circlesRows[i].map(item => {
                    item.x = currentX;
                    currentX += item.width - 600;
                })
            }
        }

        // ordenar cada renglon 
        this.circles.sort(function (a, b) {
            if (a.row == b.row) {
                return a.x - b.x
            }
        });

        // llamada al método que desarrolla la lógica de juego
        this.gameLogic();
    }

    /** 
    * Método en el que se desarrolla la lógica del juego.
    * <br>
    *  
    */ 
    gameLogic() {
        var i = 0;
        this.sprites.forEach((sprite, index) => {
            //se guardan las posiciones x e y del sprite pulsado
            let originalX = this.lettersGridConfiguration.x + (5 + this.lettersGridConfiguration.paddingX) * (index % 5);
            let originalY = sprite.y;
            sprite.on('pointerdown', () => {
                if (this.canMove && !this.circles[i].busy) {
                    if (sprite) {
                        this.canMove = true;
                        // animación de desplazamiento del sprite de la letra hacia el primer círculo (hueco) 'libre'
                        this.tweens.add({
                            targets: sprite,
                            x: this.circles[i].x,
                            y: this.circles[i].y,
                            duration: 500,
                            ease: "Power2",
                            delay: 50,
                            // mientras se desplaza el sprite, impedimos que el jugador/a pueda pulsar otra letra
                            onUpdate: () => {
                                this.canMove = false;
                            },
                            onComplete: () => {
                                sprite.setDepth(1);
                                this.circles[i] ? this.circles[i].char = sprite.texture.key : this.circles[this.sprites.length - 1].char = sprite.texture.key;
                                //------ACIERTO------
                                if (this.circles[i]?.char == this.characters[i]) {
                                    // cambiamos el color del círculo 
                                    this.circles[i].setTintFill(0x94C11E);
                                    // indicamos que el círculo al que llegó el sprite de la letra se encuentra ocupado
                                    this.circles[i].busy = true;
                                    i += 1;
                                    this.canMove = true;
                                    this.completed++;
                                    // quitamos la posibilidad de que pueda ser interactivo el sprite ya colocado para ignorar clicks sobre él
                                    sprite.removeInteractive();
                                    // al hacer el sprite más pequeño y el fondo tener el color verde deseado, provoca un efecto de bordeado
                                    sprite.setScale(0.25)
                                }
                                //-----FALLO-----
                                else {
                                    // hacemos que la última gota de la lista de gotas se ponga en un color más tenue
                                    const lastWaterDrop = this.waterDrops[this.waterDrops.length - 1];
                                    this.add.tween({
                                        targets: lastWaterDrop,
                                        duration: 1000,
                                        alpha: 0.2,
                                        onUpdate: () => {
                                            this.canMove = false;
                                        },
                                        onComplete: () => {
                                            this.canMove = true;
                                            // eliminamos la última gota de la lista
                                            this.waterDrops.pop();
                                        }
                                    });
                                    // disminuimos los intentos en una unidad
                                    this.attempts -= 1;
                                    // color del círculo en rojo para indicar el error
                                    this.circles[i].setTintFill(0xF3430F);
                                    // la posición no está ocupada
                                    this.circles[i].busy = false;
                                    // el sprite de la letra se desplaza a su posición inicial
                                    this.add.tween({
                                        targets: sprite,
                                        x: originalX,
                                        y: originalY,
                                        duration: 1000,
                                        ease: "Power2",
                                        delay: 50
                                    });
                                }

                                // en caso que se acaban los intentos o que todos los huecos estén cubiertos:
                                // generamos la palabra juntando los sprites y llamamos al método 'check()'
                                if (this.attempts == 0 || this.circles.every(item => item.busy)) {
                                    var palabra = []
                                    this.circles.map(item => {
                                        palabra.push(item.char);
                                    });
                                    this.check(palabra.join(''));
                                }
                            }
                        })
                    }
                }
            })
        })
    }

    /**
     * Método para comprobar el resultado introducido y ver si ha completado la tanda
     * <br>
     * Si le quedan intentos puede continuar, sino puede volver a intentar la tanda en la que ha perdido
     * @param {string} wordInput 
     */
    check(wordInput) {
        // creamos una variable que contiene la palabra de turno sin tildes '.normalize("NFD").replace(/[\u0300-\u036f]/g, "")'
        // y sin espacios
        var wordToCompare = this.element.word?.replaceAll(' ', '');

        // contenedor con todos los elementos del cuadro de siguiente tanda
        const winnerContainer = this.add.container(0, 0);
        const nextLevel = this.add.image(1800, 900, 'next').setAlpha(0.5);

        const nextBox1 = this.add.image(1790, 970, 'textBg').setTintFill(0xFFFFFF);
        const nextBox2 = this.add.image(1840, 970, 'textBg').setTintFill(0xFFFFFF);
        const nextBox3 = this.add.image(1890, 970, 'textBg').setTintFill(0xFFFFFF);

        const nextBtn = this.add.image(1845, 870, 'nextbtn').setScale(0.1).setInteractive({ cursor: 'pointer' });

        const nextLevelText = this.add.text(1755, 960, 'Siguiente nivel',
            { align: 'center', fontStyle: "bold", fontSize: 22, fontFamily: "Quicksand", color: '#F39C12' })
            .setDepth(1)
            .setInteractive({ cursor: 'pointer' })

        const winnerText = this.add.text(1840, 780, "¡Muy bien!",
            { align: "center", fontStyle: "bold", fontSize: 40, fontFamily: "Quicksand", color: "#fff" }
        ).setOrigin(.5)
            .setDepth(3);

        winnerContainer.add(nextLevel);
        winnerContainer.add(nextBtn);

        winnerContainer.add(winnerText);
        winnerContainer.add(nextBox1);
        winnerContainer.add(nextBox2);
        winnerContainer.add(nextBox3);
        winnerContainer.add(nextLevelText);

        winnerContainer.y = 1000;

        // icono que se muestra cuando la respuesta es correcta
        const correctAnswer = this.add.image(this.scale.width / 2, 400, 'check'
        ).setOrigin(.5)
            .setDepth(3)
            .setInteractive()
            .setScale(0);

        
        // contenedor con todos los elementos del cuadro de volver a intentarlo
        const gameOverContainer = this.add.container(0, 0);
        const restartLevel = this.add.image(1800, 900, 'next').setAlpha(0.5);

        const restartBox1 = this.add.image(1785, 970, 'textBg').setTintFill(0xFFFFFF);
        const restartBox2 = this.add.image(1840, 970, 'textBg').setTintFill(0xFFFFFF);
        const restartBox3 = this.add.image(1895, 970, 'textBg').setTintFill(0xFFFFFF);

        const restartBtn = this.add.image(1845, 870, 'restart').setScale(0.1).setInteractive({ cursor: 'pointer' });

        const restartLevelText = this.add.text(1740, 960, 'Vuelve a intentarlo',
            { align: 'center', fontStyle: "bold", fontSize: 22, fontFamily: "Quicksand", color: '#F39C12' })
            .setDepth(1)
            .setInteractive({ cursor: 'pointer' })

        const restartText = this.add.text(1840, 780, "¡Ups!",
            { align: "center", fontStyle: "bold", fontSize: 40, fontFamily: "Quicksand", color: "#fff" }
        ).setOrigin(.5)
            .setDepth(3);

        gameOverContainer.add(restartLevel);
        gameOverContainer.add(restartBtn);

        gameOverContainer.add(restartText);
        gameOverContainer.add(restartBox1);
        gameOverContainer.add(restartBox2);
        gameOverContainer.add(restartBox3);
        gameOverContainer.add(restartLevelText);

        gameOverContainer.y = 1000;
        

        if (wordInput == '') {
            return
        }

        // comprobamos que la palabra introducida es igual a la palabra de turno y si quedan palabras en la tanda
        if (wordInput.toUpperCase() === wordToCompare.toUpperCase() && this.elements.length >= 0) {
            // si se acierta la palabra, se muestra el icono 'check'
            this.add.tween({
                targets: correctAnswer,
                scale: 0.1,
                duration: 1200,
                ease: Phaser.Math.Easing.In
            });

            const score = this.add.text(530, 110, '+10',
                { align: 'center', fontStyle: "bold", fontSize: 22, fontFamily: "Quicksand", color: '#fff' });

            // animación de la puntuación obtenida
            this.add.tween({
                targets: score,
                x: 530,
                y: 60,
                duration: 1500,
                onComplete: () => {
                    this.score += 10;
                }
            });
            this.canMove = false;

            // eliminamos la palabra de la lista
            this.elements = this.elements.filter(item => item.word != this.element.word);
            // eliminamos el icono 'check' despues de 3 segundos
            setTimeout(() => {
                correctAnswer.destroy();
                this.sprites.map(item => item.destroy());
                this.circles.map(item => item.destroy());
                this.completed = 0;
                // si quedan palabras en la tanda volvemos a llamar al método que inicia el juego
                if (this.elements.length > 0) {
                    this.startGame();
                }
            }, 3000);

            // si no quedan palabras de la tanda, mostramos el cuadro de siguiente tanda
            if (this.elements.length == 0) {
                this.passed = true;
                this.add.tween({
                    targets: winnerContainer,
                    duration: 1500,
                    ease: Phaser.Math.Easing.Out,
                    y: 0,
                });
                this.canMove = false;
            }

            // se barajan las palabras restantes de nuevo
            this.shuffle();
        }
        // si no se dan los casos anteriores significa que no quedan intentos, por lo que, mostramos el texto de game over
        else {
            this.add.tween({
                targets: gameOverContainer,                
                duration: 1500,
                ease: Phaser.Math.Easing.Out,
                y: 0
            });
            this.canMove = false;
            // sprites y círculos más transparentes 
            this.sprites.map(sprite => sprite.setAlpha(0.4))
            this.circles.map(circle => circle.setAlpha(0.4));
        }

        // al pulsar en 'siguiente tanda', los elementos sprites, círculos, imagen central y texto descriptivo se van hacia abajo
        nextLevelText.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.sprites.map(item => this.add.tween({
                targets: item,
                duration: 2000,
                delay: 1000 + 200 * item,
                y: 2000,
            }));

            this.circles.map(item => this.add.tween({
                targets: item,
                duration: 2000,
                delay: 1000 + 200 * item,
                y: 2000,
            }));

            this.add.tween({
                targets: [this.circles, this.sprites, this.centerImage, this.clueText,
                    correctAnswer, winnerContainer],
                duration: 2000,
                ease: Phaser.Math.Easing.Bounce.Out,
                y: 2000,
                // al completarse la animación iniciamos el siguiente nivel
                onComplete: () => {
                    this.scene.start('secondLevel', { score: this.score });
                }
            })
        });
        // mismo comportamiento que en el caso anterior
        nextBtn.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.sprites.map(item => this.add.tween({
                targets: item,
                duration: 2000,
                delay: 1000 + 200 * item,
                y: 2000,
            }));

            this.circles.map(item => this.add.tween({
                targets: item,
                duration: 2000,
                delay: 1000 + 200 * item,
                y: 2000,
            }));

            this.add.tween({
                targets: [this.circles, this.sprites, this.centerImage, this.clueText,
                    correctAnswer, winnerContainer],
                duration: 2000,
                ease: Phaser.Math.Easing.Bounce.Out,
                y: 2000,
                onComplete: () => {
                    this.scene.start('secondLevel', { score: this.score });
                }
            })
        });
        
        // cuando se pulsa en el botón de volver a intentarlo, desaparecen los elementos y reiniciamos la tanda
        restartBtn.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.sprites.map(item => this.add.tween({
                targets: item,
                duration: 2000,
                delay: 1000 + 200 * item,
                y: 2000,
            }));
            this.circles.map(item => this.add.tween({
                targets: item,
                duration: 2000,
                delay: 1000 + 200 * item,
                y: 2000,
            }));

            this.add.tween({
                targets: [this.circles, this.sprites, this.centerImage, this.clueText,
                    gameOverContainer],
                duration: 2000,
                ease: Phaser.Math.Easing.Bounce.Out,
                y: 2000,
                onComplete: () => {
                    this.restartGame();
                }
            })
        });
        // mismo comportamiento en caso de pulsar en el texto
        restartLevelText.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.sprites.map(item => this.add.tween({
                targets: item,
                duration: 2000,
                delay: 1000 + 200 * item,
                y: 2000,
            }));
            this.circles.map(item => this.add.tween({
                targets: item,
                duration: 2000,
                delay: 1000 + 200 * item,
                y: 2000,
            }));

            this.add.tween({
                targets: [this.circles, this.sprites, this.centerImage, this.clueText,
                    gameOverContainer],
                duration: 2000,
                ease: Phaser.Math.Easing.Bounce.Out,
                y: 2000,
                onComplete: () => {
                    this.restartGame();
                }
            })
        });
    }
}