import Phaser from "phaser";

export const createCard = ({
    scene,
    x,
    y,
    frontTexture,
    cardName
}) => {

    let isFlipping = false;
    const rotation = { y: 180 };

    const backTexture = 'card-back';

    const card = scene.add.image(x, y, backTexture)
        .setName(cardName)
        .setInteractive();

    const flipCard = (callbackComplete) => {
        if (isFlipping) {
            return;
        }
        scene.add.tween({
            targets: card,
            scaleX: 0, 
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 200, 
            yoyo: true,
            onComplete: () => {
                scene.add.tween({
                    targets: [rotation],
                    y: (rotation.y === 180) ? 0 : 180,
                    ease: 'linear',
                    duration: 1,
                    onStart: () => {
                        isFlipping = true;
                        scene.add.tween({
                            targets: card,
                            ease: Phaser.Math.Easing.Expo.InOut,
                            onComplete: () => {
                                card.rotateY = 180 + rotation.y;
                                const cardRotation = Math.floor(card.rotateY) % 360;
                                if ((cardRotation >= 0 && cardRotation <= 89) || (cardRotation >= 270 && cardRotation <= 359)) {
                                    card.setTexture(frontTexture);
                                }
                                else {
                                    card.setTexture(backTexture);
                                }
                            }
                        })
                    },        
                    onComplete: () => {
                        isFlipping = false;
                        if (callbackComplete) {
                            callbackComplete();
                        }
                    }
                });
            }
        });


    }

    const destroy = () => {
        scene.add.tween({
            targets: [card],
            y: card.y - 1000,
            easing: Phaser.Math.Easing.Elastic.In,
            duration: 500,
            onComplete: () => {
                card.destroy();
            }
        })
    }

    return {
        gameObject: card,
        flip: flipCard,
        destroy,
        cardName
    }
}