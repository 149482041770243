import { createGlobalStyle } from 'styled-components';
import Quicksand from './Quicksand.ttf';

const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: 'Quicksand';
        src: url(${Quicksand}) format('truetype');
        font-weight: bold;
        font-style: normal;
    }
`;

export default GlobalFonts;