import { makeAutoObservable, flow } from "mobx";
import { makePersistable } from 'mobx-persist-store';

class generalStore {

    error = "";
    processing = [];
    boxScreenMode = true;
    mainActiveTab = "1";
    teamsPanelActiveTab = "1";

    //*********************************************************
    constructor() {
        makeAutoObservable(this);

        makePersistable(this, { name: 'generalStore', properties: ['processing', 'boxScreenMode'], storage: window.sessionStorage });
    }

    //*********************************************************
    doActionGeneralError = flow(function* (error) {
        this.error = error;
    });

    //*********************************************************
    doActionRemoveGeneralError = flow(function* () {
        this.error = "";
    });

    //*********************************************************
    doActionStartService = flow(function* (service) {
        this.processing = [...this.processing, service];
    });

    //*********************************************************
    doActionEndService = flow(function* (service) {
        this.processing = this.processing.filter(item => item !== service);
    });

    //*********************************************************
    doActionSetTeamsPanelActiveTab = flow(function* (panel) {
        this.teamsPanelActiveTab = panel;
    });

    //*********************************************************
    doActionSetMainActiveTab = flow(function* (panel) {
        this.mainActiveTab= panel;
    });

    //*********************************************************
    doActionChangeScreenMode = flow(function* (boxMode) {
        this.boxScreenMode = boxMode;
    });
}

export default new generalStore()