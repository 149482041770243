import axios from "axios";
import configApp from '../configApp.js';

//*********************************************************
export const getTeamsListService = () => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/teams/list");
    return (response);
}

//*********************************************************
export const getPaginatedListOfTeams = (filter, page, order, pageSize) => {
    let params = "";

    if (filter.text != undefined && String(filter.text).length > 0) params = params + "text=" + filter.text + "&";

    if (filter.idManager != undefined) {
        params = params + "idManager=" + filter.idManager + "&";
    }

    if (filter.idAcademicYear != undefined) {
        params = params + "idAcademicYear=" + filter.idAcademicYear + "&";
    }

    if (page != undefined) {
        params = params + "_page=" + page + "&";
    }

    if (pageSize != undefined) {
        params = params + "_pagesize=" + pageSize + "&";
    }

    if (order != undefined) {
        params = params + "_orderby=" + order;
    }

    let response = axios.get(configApp.getParams().BACKEND_API_URL + "teams/paginated-list?" + params);

    return (response);
}

//*********************************************************
export const getTeamsListByOrganization = (idOrganization) => {
    if (idOrganization != undefined) {
        const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/teams/list-by-organization/" + idOrganization);
        return (response);
    }
}

//*********************************************************
export const getAvatarsService = () => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/catalog/avatars");
    return (response);
}

//*********************************************************
export const getRiversService = () => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/catalog/rivers");
    return (response);
}

//*********************************************************
export const getGradesService = () => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/catalog/grades");
    return (response);
}

//*********************************************************
export const getLevelsListService = () => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/levels/list");
    return (response);
}

//*********************************************************
export const getStepsListService = () => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/steps/list");
    return (response);
}

//*********************************************************
export const getThreatsListService = () => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/threats/list");
    return (response);
}

//*********************************************************
export const getRankingPlayerByTeam = (idUser, idTeam) => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/teams/" + idUser + "/rankingPlayerTeam/" + idTeam);
    return (response);
}

//*********************************************************
export const getTeamsListServiceOrderByScore = () => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/teams/listOrderByScore");
    return (response);
}

//*********************************************************
export const getAcademicYearsService = () => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/catalog/academic-years");
    return (response);
}

//*********************************************************
export const saveTeamEditService = (params) => {
    if (params.id != undefined) {
        const response = axios.post(configApp.getParams().BACKEND_API_URL + "teams/" + params.id + "/change-data", params);
        return (response);
    }
    else {
        const response = axios.post(configApp.getParams().BACKEND_API_URL + "teams/new", params);
        return (response);
    }
}

//*********************************************************
export const changeTeamsStep = (idTeam, idStep) => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "teams/" + idTeam + "/changeStep/" + idStep);
    return (response);
}

//*********************************************************
export const removeTeamService = (id) => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "teams/" + id + "/remove");

    return (response);
}

//*********************************************************
export const saveTeamsJsonService = (idTeam, data) => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "teams/" + idTeam + "/saveJson/" + data);

    return (response);
}

//*********************************************************
export const getTeamsAvatarService = (idTeam) => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "static/teams_avatar/" + idTeam + "_cropped.png");

    return (response);
}