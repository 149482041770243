import { makeAutoObservable, flow } from "mobx";
import { makePersistable } from "mobx-persist-store";

import * as Actions from './actionFlags.js';
import generalStore from "./generalStore.js";

import { callServiceEventAware } from "./index.js";
import { getOrganizationsListService, getCouncilsService, saveOrganizationEditService, getPaginatedListOfOrganizations, removeOrganizationService, getProvincesService } from "../services/OrganizationService.js";


class organizationsStore {
    organizations = {};
    changedOrganizationDataFlag = false;
    organizationsFilter = {};
    councils = [];
    organizationsOrderBy = "name_ASC";
    resetOrganizationsList = false;
    message = "";
    error = "";
    
    //*********************************************************
    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
        makePersistable(this, { name: 'organizationsStore', properties: ['organizations', 'organizationsFilter', 'organizationsOrderBy'], storage: window.sessionStorage });
    }

    //*********************************************************
    doActionRemoveMessage = flow(function*() {
        this.message = "";
    });

    //*********************************************************
    doActionChangeOrderBy = flow(function* (orderBy) {
        this.organizationsOrderBy = orderBy;
    });

    //*********************************************************
    //CAMBIAR A LIST NORMAL Y CREAR EL PAGINADO
    doActionGetPaginatedListOfOrganizations = flow(function *(resetList, filter, page, pageSize){

        try {
            const response = yield callServiceEventAware(Actions.GET_PAGINATED_LIST_OF_ORGANIZATIONS, getPaginatedListOfOrganizations, filter, page, this.organizationsOrderBy, pageSize);
            const organizations = response.data;

            let currentOrganizations;
            let hasMore = true;

            if (page > 0){
                currentOrganizations = this.organizations.list;

                if (organizations.length > 0)
                    currentOrganizations = currentOrganizations.concat(organizations);
                else
                    hasMore = false;
            }
            else{
                currentOrganizations = organizations;
            }

            this.resetOrganizationsList = resetList;
            this.organizationsFilter = filter;
            this.organizations = { hasMore: hasMore, page: page, list: currentOrganizations };
            
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN organizationsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionEndService(Actions.GET_PAGINATED_LIST_OF_ORGANIZATIONS);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);            
        }
    });

    //*********************************************************
    doActionGetOrganizationsList = flow(function *() {
        try {
            const response = yield callServiceEventAware(Actions.GET_LIST_OF_ORGANIZATIONS, getOrganizationsListService);
            this.organizations.list = response.data;

        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN organizationsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionEndService(Actions.GET_LIST_OF_ORGANIZATIONS);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    })

    //*********************************************************
    doActionRemoveOrganization = flow(function* (data) {
        try {
            yield callServiceEventAware(Actions.ORGANIZATION_REMOVE, removeOrganizationService, data);

            if (this.organizations.list != undefined) {
                let currentOrganizations = [...this.organizations.list.filter(item => item.id != data)];                

                this.message = "Se ha eliminado correctamente el colegio"
                this.organizations = { list: currentOrganizations };
            }
            
        } catch (error) {
            generalStore.doActionEndService(Actions.ORGANIZATION_REMOVE);
            console.log("ERROR EN LLAMADA AL API EN organizationsStore.js: " + error.message);
            console.log(error);

            if (error.response != null) {
                if (error.response.status == 500) {
                    generalStore.doActionGeneralError("Error al eliminar el colegio");
                }
                else if (error.response.status == 409) {
                    generalStore.doActionGeneralError("Error al eliminar el colegio. Actualmente está en uso.");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
        }
    });

    //*********************************************************
    doActionGetCouncilsList = flow(function*(){
        try {
            const response = yield getCouncilsService();

            this.councils = response.data;
            
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN organizationsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);            
        }
    });

    //*********************************************************
    doActionGetProvincesList = flow(function *() {
        try {
            const response = yield getProvincesService();

            this.provinces = response.data;

        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN organizationsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    })

    //*********************************************************
    doActionSaveOrganizationEdit = flow(function* (data){
        try {
            const response = yield callServiceEventAware(Actions.ORGANIZATION_SAVE_DATA, saveOrganizationEditService, data);
            const organization = response.data;
            
            let currentOrganizations = [];

            if (this.organizations.list == undefined) {
                this.organizations = { list: [organization] };
            }
            else {
                currentOrganizations = [organization, ...this.organizations.list.filter(item => item.id != organization.id)]
                currentOrganizations.sort(this.sortOrganizations(this.organizationsOrderBy));

                this.message = "Los datos han sido guardados correctamente";
                this.organizations = { list: currentOrganizations }
            }            
        } catch (error) {
            generalStore.doActionEndService(Actions.ORGANIZATION_SAVE_DATA);
            console.log("ERROR EN LLAMADA AL API EN organizationsStore.js: " + error.message);
            console.log(error);

            if (error.response != null) {
                if (error.response.status == 422) {
                    if (error.response.data != undefined  && error.response.data.code!= undefined)
                        generalStore.doActionGeneralError("Ya existe un colegio con este email");
                    else
                        generalStore.doActionGeneralError("Error al guardar los datos");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
        }
    });

    //*********************************************************
    sortOrganizations(sortBy) {
        return function (a, b) {
            let order = sortBy.split("_");

            if (order[1].toUpperCase() == "ASC"){
                return eval('a.' + order[0] + '.toString().localeCompare(b.' + order[0] + '.toString())');
            }else
                return eval('b.' + order[0] + '.toString().localeCompare(a.' + order[0] + '.toString())');
        }
    }
}
export default new organizationsStore();