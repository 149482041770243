import React from "react";


import { Button, Row, Col } from "antd";

//Para poder utilizar 'this.props.history.push()' en un componente
import { withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";

import GlobalFonts from "../assets/fonts/fonts";
import botonPlay from "../assets/icons/boton_play.png";

import './ThreatPlayComponent.scss';

import { reaction } from "mobx";
import { message } from "antd";
import LevelsModal from "./LevelsModal";

class ThreatComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showMoreInfo: false,
            play: false,
            showLevelsModal: false
        };

        this.reactions = [];
    }

    //*********************************************************
    componentDidMount() {
        this.handleLoadSteps();

        this.reactions.push(
            reaction(
                () => this.props.teamsStore.steps,
                () => {
                    if (this.props.teamsStore.steps == []) {
                        this.handleLoadSteps();
                    }
                }
            )
        )
    }

    //*********************************************************
    handleLoadSteps() {
        this.props.teamsStore.doActionGetThreatsList();
    }

    //*********************************************************
    handleChangeBackground(step) {
        if (step.price <= this.props.team.score) {
            var idTeam = this.props.team.id;
            var idStep = step.id;
            this.props.teamsStore.doActionChangeTeamsStep(idTeam, idStep);
            window.location.reload();
        } else {
            message.error("Tu equipo no tiene suficientes puntos para adquirir esta mejora. ¡Debéis de jugar a los minijuegos para obtener más puntos!")
        }
    }

    //*********************************************************
    toggleInfo() {
        this.setState({ showMoreInfo: !this.state.showMoreInfo });
    }

    //*********************************************************
    playThreat(team, step) {
        // rol.id == 3 (TEAMPLAYER)
        if (this.props.user.role.id != 3) {
            this.props.teamsStore.doActionChangeTeamsStep(team.id, step.id);
            window.location.reload();
            this.setState({ play: true });
        }
        else {
            message.warning("Las mejoras del río solo las puede realizar el/la profesor/a.")
        }
    }

    //*********************************************************
    openLevelsModal() {
        this.setState({ showLevelsModal: true });
    }

    //*********************************************************
    render() {
        const userLogged = this.props.user;

        const teamsList = this.props.teamsStore.teams == undefined ? [] : this.props.teamsStore.teams.list;

        const threatsList = this.props.teamsStore.threats == undefined ? [] : this.props.teamsStore.threats;

        const userLoggedTeam = userLogged.role.name == "PLAYER" ? userLogged.team :
            (userLogged.role.name == "MANAGER" ? this.props.team : teamsList?.find(item => {
                return item.id == userLogged.team.id
            }));

        const step = this.props.step;

        const threat = threatsList.find(item => item.step.id == step.id);

        return (
            <>
                <GlobalFonts />
                {/* <div className="threatPlayComponent" >
                    {!this.state.play &&
                        <div className="background-threat-play" onClick={() => this.playThreat(userLoggedTeam, step)}>
                            <Row justify='center' align="top">
                                <Col >


                                    <div className="threat">
                                        <div className="threat-button-threat">
                                            <Button className="button-threat-play-with-image">
                                                <img src={botonPlay} className="buttonIcons" alt="Reproducir amenaza" />
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col >
                                    <div className="threat">
                                        <div className="threat-text">
                                            <p>{threat?.textPlay.split('&').map((element, index) => {
                                                if (index != 0 && index % 2 != 0) {
                                                    return <span className="text-orange">{element}</span>;
                                                }
                                                else {
                                                    return <span>{element}</span>
                                                }
                                            })}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    }
                </div> */}

<div className="threatPlayComponent-unique">
  {!this.state.play && (
    <div className="background-threat-play-unique" onClick={() => this.playThreat(userLoggedTeam, step)}>
      <div className="threat-content-unique">
        <div className="threat-button-threat-unique">
          <Button className="button-threat-play-with-image-unique">
            <img src={botonPlay} className="buttonIcons-unique" alt="Reproducir amenaza" />
          </Button>
        </div>
        <div className="threat-text-unique">
          <p>
            {threat?.textPlay.split('&').map((element, index) => {
              if (index % 2 !== 0) {
                return <span key={index} className="text-orange-unique">{element}</span>;
              }
              return <span key={index}>{element}</span>;
            })}
          </p>
        </div>
      </div>
    </div>
  )}
</div>

            </>
        )
    }
}

export default withRouter(inject('teamsStore', 'generalStore')(observer(ThreatComponent)));
