import axios from 'axios';
import configApp from '../configApp.js';

/*
export const registerNewUserService = (params) => {
    const response = axios.post(configApp.getParams().BACKEND_API_URL+"user/new/", params);
    return (response);
}
*/

//*********************************************************
export const getPaginatedListOfUsers = (filter, page, order, pageSize) => {

    let params = "";

    // eslint-disable-next-line
    if (filter.role != undefined) {
        params = params + "role=" + filter.role + "&";
    }

    // eslint-disable-next-line
    if (filter.text != undefined && String(filter.text).length > 0) params = params + "text=" + filter.text + "&";

    // eslint-disable-next-line
    if (filter.showDisabled != undefined && String(filter.showDisabled).length > 0) params = params + "showDisabled=" + filter.showDisabled + "&";

    // eslint-disable-next-line
    if (pageSize != undefined) {
        params = params + "_pagesize=" + pageSize + "&";
    }

    if (order != undefined) {
        params = params + "_orderby=" + order;
    }

    let response = axios.get(configApp.getParams().BACKEND_API_URL + "users/paginated-list?_page=" + page + "&" + params);

    return (response);
}

//*********************************************************
export const getUserDataService = (idUser) => {
    if (idUser != undefined) {
        const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/users/" + idUser + "/get-data");
        return (response);
    }
} 

//*********************************************************
export const changedUserDataService = (params) => {
    const response = axios.post(configApp.getParams().BACKEND_API_URL + "users/change-data", params);
    return (response);
}

//*********************************************************
export const changePasswordService = (token, password) => {
    let params = {
        "token": token,
        "password": password
    }

    const response = axios.post(configApp.getParams().BACKEND_API_URL + "authenticate-recovery", params);
    return (response);
}

//*********************************************************
export const resetPasswordService = (password) => {
    let params = {
        "password": password
    }
    const response = axios.post(configApp.getParams().BACKEND_API_URL + "users/change-password", params);
    return (response);
}

//*********************************************************
export const saveUserEditService = (params) => {

    if (params.id != undefined) {
        const response = axios.post(configApp.getParams().BACKEND_API_URL + "users/" + params.id + "/change-data", params);
        return (response);
    }
    else {
        const response = axios.post(configApp.getParams().BACKEND_API_URL + "users/new", params);
        return (response);

    }
}

//*********************************************************
export const savePlayerScoreService = (params) => {
    let data = new FormData();
    data.append("score", params.score);

    if (params.id != undefined) {
        const response = axios.post(configApp.getParams().BACKEND_API_URL + "public/users/" + params.id + "/save-score", data);
        return (response);
    }
}

//*********************************************************
export const sendUserByEmailService = (email) => {

    const url = configApp.getParams().RECOVERY_USER;

    let params = {
        "email": email,
        "resetPasswordURL": url
    };

    const response = axios.post(configApp.getParams().BACKEND_API_URL + "users/send-user-by-email", params);
    return (response);
}

//*********************************************************
export const registerUserService = (params) => {
    const validationUrl = configApp.getParams().ACTIVATION_USER;

    params.validationUrl = validationUrl;

    const response = axios.post(configApp.getParams().BACKEND_API_URL + "users/register", params);

    return (response);
}

//*********************************************************
export const registerPlayerService = (params) => {
    const validationUrl = configApp.getParams().ACTIVATION_USER;

    params.validationUrl = validationUrl;

    const response = axios.post(configApp.getParams().BACKEND_API_URL + "users/register-player", params);
    console.log("response: ", response);
    return (response);
}

//*********************************************************
export const changeEnabledUserStateService = (user, disabled) => {
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "users/" + user.id + "/set-disabled/" + disabled + "/");
    return (response);
}

//*********************************************************
export const removeUserService = (id) => {

    const response = axios.get(configApp.getParams().BACKEND_API_URL + "users/" + id + "/remove");

    // Devolver la respuesta, aunque para este tipo de operaciones venga vacía, para capturar el error en caso de que se produzca alguno
    return (response);
}
//*********************************************************
export const getTeamPlayersByTeam = (idTeam) => {   
    const response = axios.get(configApp.getParams().BACKEND_API_URL + "public/teams/getTeamPlayersByTeam/" + idTeam);
    return (response);
}
