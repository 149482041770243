import React from 'react';

import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Button, Col, Form, Input, Layout, message, Row } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, SyncOutlined } from '@ant-design/icons';

import * as Actions from '../stores/actionFlags';

import './ChangePassword.scss'

const { Content } = Layout;

class ChangePassword extends React.Component {

    //*********************************************************
    constructor(props) {
        super(props);

        this.reactions = [];
    }

    //*********************************************************
    componentDidMount() {
        this.reactions.push(
            reaction(
                () => this.props.generalStore.error || this.props.usersStore.changedPasswordFlag || this.props.usersStore.error,
                () => {
                    const changedPasswordFlag = this.props.usersStore.changedPasswordFlag;
                    const userError=this.props.usersStore.error;
                    const error = this.props.generalStore.error;
                    if (userError != undefined && userError.length > 0) {
                        message.error(userError);
                        this.props.generalStore.doActionRemoveGeneralError();
                        this.props.history.push('/');
                    }
                    if (error != undefined && error.length > 0) {
                        message.error(error);
                        this.props.generalStore.doActionRemoveGeneralError();
                    }
                    else if (changedPasswordFlag) {
                        message.success("La contraseña se ha modificado correctamente");
                        this.props.history.push('/teamspanel');
                    }
                }
            )
        )
    }

    //*********************************************************
    componentWillUnmount() {
        this.reactions.forEach((dispose) => dispose());
    }

    //*********************************************************
    handleChangePasswordSubmit = (password) => {
        const token = window.location.href.split('=')[1];
        this.props.usersStore.doActionChangePassword(token, password.password);
    }

    //*********************************************************
    handleResetPasswordSubmit = (password) => {
        this.props.usersStore.doActionResetPassword(password.password);
    }

    //*********************************************************
    render() {

        const isChangingPassword = this.props.generalStore.processing.includes(Actions.CHANGE_PASSWORD);
        const resetPassword = this.props.usersStore.userLogged.resetPassword;

        return (
            <>
                <Layout className="changepassword-layout">
                    <Content>

                        <Row justify="center" align="top">
                            <Col className="center-content" xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 20 }}>
                                <h2 className="app-subtitle">Sistema de Gestión de Usuarios</h2>
                            </Col>
                        </Row>

                        <Row justify="center" align="bottom">
                            <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 16 }} lg={{ span: 12 }}>

                                <Form className="changepassword-form" initialValues={{ remember: true }} onFinish={resetPassword ? this.handleResetPasswordSubmit : this.handleChangePasswordSubmit}>

                                    {resetPassword && <p className="app-hello">Hola {this.props.usersStore.userLogged.name}, por políticas de seguridad, debes crear una nueva contraseña de acceso.</p>}
                                    {!resetPassword && <p className="app-hello">Introduce la nueva contraseña.</p>}

                                    <Form.Item className="form-field" name="password" rules={[{ required: true, message: 'Introduce tu nueva contraseña' }, { min: 6, message: 'La contraseña debe tener al menos 6 caracteres' }]} >
                                        <Input.Password prefix="Nueva contraseña" type="password" placeholder="Nueva contraseña" 
                                        iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined />)}
                                        autoComplete="new-password" />
                                    </Form.Item>

                                    <Form.Item className="form-field" name="passwordRepeat" 
                                    rules={[
                                        { required: true, message: 'Repite tu nueva contraseña' }, 
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                            if (getFieldValue('password')!='' &&  (value===undefined ||getFieldValue('password') != value)) {
                                                return Promise.reject(new Error('Las dos contraseñas introducidas no coinciden'));  
                                            } 
                                            return Promise.resolve();
                                          
                                            }
                                        })  
                                    ]} >
                                        <Input.Password prefix="Repetir contraseña" type="password" placeholder="Repita la contraseña" 
                                        iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined />)}
                                        autoComplete="new-password" />
                                    </Form.Item>

                                    <Form.Item>
                                        {!isChangingPassword && <Button type="primary" htmlType="submit" className="login-form-button">CAMBIAR CONTRASEÑA</Button>}
                                        {isChangingPassword && <Button type="primary" disabled style={{ backgroundColor: `#AAAAAA` }} className="login-form-button"><SyncOutlined spin /></Button>}
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Content>

                </Layout>
            </>
        )
    }
}
export default inject('generalStore', 'usersStore')(observer(ChangePassword));