import { makeAutoObservable, flow } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { callServiceEventAware } from ".";
import {
    getPaginatedListOfTeams, getAvatarsService, getRiversService, getGradesService, saveTeamEditService, getAcademicYearsService,
    getTeamsListService, removeTeamService, getTeamsListByOrganization, getLevelsListService, getStepsListService, getThreatsListService,
    getTeamsListServiceOrderByScore, getRankingPlayerByTeam, changeTeamsStep, saveTeamsJsonService, getTeamsAvatarService
} from "../services/TeamService";

import * as Actions from './actionFlags.js';
import generalStore from "./generalStore";

class teamsStore {
    teams = {};
    personalRanking = 0;
    avatar = '';
    changedTeamDataFlag = false;
    teamsFilter = {};
    teamsOrderBy = "name_ASC";
    resetTeamsList = false;
    sortedTeams = [];
    avatars = [];
    rivers = [];
    grades = [];
    managers = [];
    academicYears = [];
    levels = [];
    steps = [];
    threats = [];
    changeBackgroundFlag = false;
    background = "";
    message = "";
    error = "";

    //*********************************************************
    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
        makePersistable(this, { name: 'teamsStore', properties: ['teams', 'teamsFilter', 'teamsOrderBy'], storage: window.sessionStorage });
    }

    //*********************************************************
    doActionRemoveMessage = flow(function* () {
        this.message = "";
    });

    //*********************************************************
    doActionChangeOrderBy = flow(function* (orderBy) {
        this.teamsOrderBy = orderBy;
    });

    //*********************************************************
    doActionGetPaginatedListOfTeams = flow(function* (resetList, filter, page, pageSize) {
        try {
            const response = yield callServiceEventAware(Actions.GET_PAGINATED_LIST_OF_TEAMS, getPaginatedListOfTeams, filter, page, this.teamsOrderBy, pageSize);
            const teams = response.data;
            this.managers = response.data.manager;

            let currentTeams;
            let hasMore = true;

            if (page > 0) {
                currentTeams = this.teams.list;

                if (teams.length > 0)
                    currentTeams = currentTeams.concat(teams);
                else
                    hasMore = false;
            }
            else {
                currentTeams = teams;
            }

            this.resetTeamsList = resetList;
            this.teamsFilter = filter;
            this.teams = { hasMore: hasMore, page: page, list: currentTeams };
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionEndService(Actions.GET_PAGINATED_LIST_OF_TEAMS);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });

    //*********************************************************
    doActionGetTeamsList = flow(function* () {
        try {
            const response = yield callServiceEventAware(Actions.GET_LIST_OF_TEAMS, getTeamsListService);
            this.teams.list = response.data;
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionEndService(Actions.GET_LIST_OF_TEAMS);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    })

    //*********************************************************
    doActionGetLevelsList = flow(function* () {
        try {
            const response = yield getLevelsListService();
            this.levels = response.data;
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    })

    //*********************************************************
    doActionGetStepsList = flow(function* () {
        try {
            const response = yield getStepsListService();
            this.steps = response.data;
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    })

    //*********************************************************
    doActionGetThreatsList = flow(function* () {
        try {
            const response = yield getThreatsListService();
            this.threats = response.data;
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    })

    //*********************************************************
    doActionGetTeamsListOrderByScore = flow(function* () {
        try {
            const response = yield callServiceEventAware(Actions.GET_LIST_OF_TEAMS, getTeamsListServiceOrderByScore);
            this.sortedTeams = response.data;            
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionEndService(Actions.GET_LIST_OF_TEAMS);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    })

    //*********************************************************
    doActionGetRankingPlayerByTeam = flow(function* (idUser, idTeam) {
        try {
            const response = yield callServiceEventAware(Actions.GET_RANKING_USER_BY_TEAM, getRankingPlayerByTeam, idUser, idTeam);
            this.personalRanking = response.data;
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js - doActionGetRankingPlayerByTeam: " + error.message);
            console.log(error);
            generalStore.doActionEndService(Actions.GET_RANKING_USER_BY_TEAM);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    })

    //*********************************************************
    doActionGetTeamsListByOrganization = flow(function* (idOrganization) {
        try {
            const response = yield callServiceEventAware(Actions.GET_LIST_OF_TEAMS_BY_ORGANIZATION, getTeamsListByOrganization, idOrganization);
            this.teams.list = response.data;
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionEndService(Actions.GET_LIST_OF_TEAMS_BY_ORGANIZATION);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    })

    //*********************************************************
    doActionChangeTeamsStep = flow(function* (idTeam, idStep) {
        try {
            const step = this.steps.find(item => item.id == idStep);
            console.log("NEXT STEP ", step)
            const response = yield callServiceEventAware(Actions.CHANGE_STEP, changeTeamsStep, idTeam, idStep);
            this.changeBackgroundFlag = true;
            this.background = response.data.step.background;
            console.log("Response changeStep: ", step, response)
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    })

    //*********************************************************
    doActionGetAvatarsList = flow(function* () {
        try {
            const response = yield getAvatarsService();
            this.avatars = response.data;
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });

    //*********************************************************
    doActionGetRiversList = flow(function* () {
        try {
            const response = yield getRiversService();
            this.rivers = response.data;
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });

    //*********************************************************
    doActionGetGradesList = flow(function* () {
        try {
            const response = yield getGradesService();
            this.grades = response.data;
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });

    //*********************************************************
    doActionGetAcademicYearsList = flow(function* () {
        try {
            const response = yield getAcademicYearsService();
            this.academicYears = response.data;
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });

    //*********************************************************
    doActionSaveTeamEdit = flow(function* (data) {
        try {
            const response = yield callServiceEventAware(Actions.TEAM_SAVE_DATA, saveTeamEditService, data);
            const team = response.data;

            let currentTeams = [];

            if (this.teams.list == undefined) {
                this.teams = { list: [team] };
            }
            else {
                currentTeams = [team, ...this.teams.list.filter(item => item.id != team.id)]
                currentTeams.sort(this.sortTeams(this.teamsOrderBy));

                this.message = "Los datos han sido guardados correctamente";
                this.teams = { list: currentTeams }
            }
        } catch (error) {
            generalStore.doActionEndService(Actions.TEAM_SAVE_DATA);
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);

            if (error.response != null) {
                if (error.response.status == 422) {
                    generalStore.doActionGeneralError("Error al guardar los datos");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
        }
    });

    //*********************************************************
    doActionRemoveTeam = flow(function* (id) {
        try {
            yield callServiceEventAware(Actions.TEAM_REMOVE, removeTeamService, id);

            if (this.teams.list != undefined) {
                let currentTeams = [...this.teams.list.filter(item => item.id != id)];

                this.message = "Se ha eliminado el equipo correctamente"
                this.teams = { list: currentTeams };
            }

        } catch (error) {
            generalStore.doActionEndService(Actions.TEAM_REMOVE);
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);

            if (error.response != null) {
                if (error.response.status == 500) {
                    generalStore.doActionGeneralError("Error al eliminar el equipo");
                }
                else if (error.response.status == 409) {
                    generalStore.doActionGeneralError("Error al eliminar el equipo. Actualmente está dado de alta y con jugadores asignados.");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
        }
    })

    //********************************************************
    doActionSaveJsonTeam = flow(function* (idTeam, data) {
        console.log("teamsStore.js - doActionSaveJsonTeam - idTeam:", data);
        try {
            const response = yield callServiceEventAware(Actions.TEAM_SAVE_DATA, saveTeamsJsonService, idTeam, data);
            console.log("Response saveJsonTeams:", response)
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    })

    //********************************************************
    doActionGetTeamsAvatarImage = flow(function* (idTeam) {
        try {
            const response = yield callServiceEventAware(Actions.TEAM_GET_AVATAR, getTeamsAvatarService, idTeam);
            console.log(response);
            this.avatar = response.data;
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN teamsStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    })

    //*********************************************************
    sortTeams(sortBy) {
        return function (a, b) {
            let order = sortBy.split("_");

            if (order[1].toUpperCase() == "ASC") {
                return eval('a.' + order[0] + '.toString().localeCompare(b.' + order[0] + '.toString())');
            } else
                return eval('b.' + order[0] + '.toString().localeCompare(a.' + order[0] + '.toString())');
        }
    }

}
export default new teamsStore();