import React from "react";

import configApp from '../configApp.js';

import { Modal, Button, Row, Col } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

//Para poder utilizar 'this.props.history.push()' en un componente
import { withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";

import icon from '../assets/images/buttons/ranking.png';
import headAvatar from '../assets/images/headAvatar.png';

import copaOro from "../assets/icons/copaOro.png";
import copaPlata from "../assets/icons/copaPlata.png";
import copaBronce from "../assets/icons/copaBronce.png";
import GlobalFonts from "../assets/fonts/fonts";

import './RankingModal.scss';
import { EllipsisOutlined } from '@ant-design/icons';

import { reaction } from "mobx";
import { List } from "antd";
import { Empty } from "antd";

class RankingModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            personalRanking: 0,
            show: false,
            title: '',
            icon: null,
            bg: 'rgb(3, 161, 154)'
        };

        this.reactions = [];
    }

    //*********************************************************
    componentDidMount() {
        this.handleLoadTeams();
        this.getPersonalRanking();
        this.getTeamPlayersByTeam();

        this.reactions.push(
            reaction(
                () => this.props.show,
                () => {
                    if (this.props.show == true) {
                        this.setState({ show: this.props.show })
                    }
                }
            ),
            reaction(
                () => this.props.team,
                () => {
                    const team = this.props.team;
                    const jsonConfig = team.image_config;
                    if (jsonConfig != undefined) {
                        var bg = jsonConfig.split(',"')[0].split(":")[1].replace(/"/g, '');
                        this.setState({ bg: bg });
                    }
                }
            )

        )
    }

    //*********************************************************
    handleLoadTeams() {
        this.props.teamsStore.doActionGetTeamsListOrderByScore();
    }


    //*********************************************************
    getPersonalRanking() {
        if (this.props.usersStore.userLogged.team != undefined)
            this.props.teamsStore.doActionGetRankingPlayerByTeam(this.props.usersStore.userLogged?.id, this.props.usersStore.userLogged.team?.id);
    }

     //*********************************************************
     getTeamPlayersByTeam() {        
        if (this.props.usersStore.userLogged?.team != undefined)
            this.props.usersStore.doActionGetTeamPlayersByTeam(this.props.usersStore.userLogged.team?.id);
    }
    //*********************************************************
    render() {
        const teams = this.props.teamsStore.sortedTeams == [] ? [] : this.props.teamsStore.sortedTeams;
        let numTeamPlayersByTeam =0;
         //Obtenemos el número de jugadores del equipo al que pertenece el jugador logueado
        if(this.props.usersStore.users !=null)
            numTeamPlayersByTeam = this.props.usersStore.users.length

      
        let idTeamOfPlayerLogged = 0;
        let idPlayerLogged = 0;

        if (this.props.usersStore.userLogged != undefined) {
            idPlayerLogged = this.props.usersStore.userLogged?.id;
            if (this.props.usersStore.userLogged.team != undefined)
                idTeamOfPlayerLogged = this.props.usersStore.userLogged.team?.id;
            else {
                const userLoggedTeam = this.props.team;
                idTeamOfPlayerLogged = userLoggedTeam?.id;
            }
        }

        let numberTeams = 0;
        if (teams != null)
            numberTeams = teams.length;

        //Función para buscar la posición del equipo en el array original
        const encontrarPosicionEquipo = (equipo, idTeamOfPlayerLogged) => {
            return equipo.findIndex(registro => registro.id === idTeamOfPlayerLogged);
        };


        //Obtenemos el ranking del equipo al que pertenece el jugador logueado
        const rankingEquipoUsuarioLogueado = (encontrarPosicionEquipo(teams, idTeamOfPlayerLogged)) + 1;

     
        //Array con los 6 equipos con mayor puntuación        
        let listaPrimerosRegistros;
        if (numberTeams >= 6)
            listaPrimerosRegistros = teams.slice(0, 6);
        else //Si hay menos de 6 equipos el array contendrá los equipos que existan
            listaPrimerosRegistros = teams.slice(0, numberTeams);

        let listaSiguientesRegistros = null;

        let arrayCombinado = null;

        let arrayPuntosSuspensivos = ['...'];

        /*Si el equipo se encuenta entre los 6 primeros, y no hay más registros se muestran los x primeros (nunca más de 6) */
        if ((rankingEquipoUsuarioLogueado < 7) && (numberTeams <= 6)) {
            // 1
            arrayCombinado = [...listaPrimerosRegistros];
        }/*Si el equipo se encuenta entre los 6 primeros, y hay más registros se muestran los 6 primeros y ... para indicar que hay más*/
        else if ((rankingEquipoUsuarioLogueado < 7) && (numberTeams > 6)) {
            // 2
            arrayCombinado = [...listaPrimerosRegistros, ...arrayPuntosSuspensivos];
            /*Si el equipo está en la posición 7 y hay 7 u 8 equipos*/
        } else if (rankingEquipoUsuarioLogueado === 7 && numberTeams > 6 && numberTeams < 9) {
            // 3
            /*Si el equipo está en la posición 7 y hay 7 equipos, se mostrarán los 6 primeros, y a continuación el 7 equipo*/
            if (rankingEquipoUsuarioLogueado === 7 && numberTeams === 7) {
                // 4
                listaSiguientesRegistros = teams.slice(6, 7)
                arrayCombinado = [...listaPrimerosRegistros, ...listaSiguientesRegistros]
                /*Si el equipo está en la posición 7 y hay 8 equipos, se mostrarán los 6 primeros registros, y a continuación el 7 y 8 equipo*/
            } else if (rankingEquipoUsuarioLogueado === 7 && numberTeams === 8) {
                // 5
                listaSiguientesRegistros = teams.slice(5, 8)
                arrayCombinado = [...listaPrimerosRegistros, ...listaSiguientesRegistros]
            } /*Si el equipo está en la posición 7 y hay más de 8 equipos, se mostrarán los 6 primeros registros, a continuación el 7 y 8 equipo y luego ... para idnicar que hay más*/
            else if (rankingEquipoUsuarioLogueado === 7 && numberTeams > 8) {
                // 6
                listaSiguientesRegistros = teams.slice(5, 8)
                arrayCombinado = [...listaPrimerosRegistros, ...listaSiguientesRegistros, ...arrayPuntosSuspensivos];
            }
            /*Si el equipo está en la posición 7 y hay más de 99 equipos*/
        } else if (rankingEquipoUsuarioLogueado === 7 && numberTeams > 9) {
            // 7
            /*Si el equipo está en la posición 7 y hay más de 9 equipos, se mostrarán los 6 primeros,  y a continuación el 7 equipo y 8 y luego puntos suspensivos*/
            listaSiguientesRegistros = teams.slice(6, 8)
            arrayCombinado = [...listaPrimerosRegistros, ...listaSiguientesRegistros, ...arrayPuntosSuspensivos]
            /*Si el equipo está en una posición por delante de la 7, ya tenemos que poner ... después de los primeros 6 equipos */
        } else if (rankingEquipoUsuarioLogueado > 7 && rankingEquipoUsuarioLogueado <= numberTeams) {
            // 8
            /*Si el equipo se encuentra en la última posición del array de equipos totales */

            if (rankingEquipoUsuarioLogueado === numberTeams) {
                //9
                listaSiguientesRegistros = teams.slice(rankingEquipoUsuarioLogueado - 1, rankingEquipoUsuarioLogueado)
                arrayCombinado = [...listaPrimerosRegistros, ...arrayPuntosSuspensivos, ...listaSiguientesRegistros]
                /*Si el equipo se encuentra en la antepenúltima posición del array de equipos totales */
            }

            else if ((rankingEquipoUsuarioLogueado + 2) === numberTeams) {
                // 10
                listaSiguientesRegistros = teams.slice(rankingEquipoUsuarioLogueado - 1, rankingEquipoUsuarioLogueado + 1)
                arrayCombinado = [...listaPrimerosRegistros, ...arrayPuntosSuspensivos, ...listaSiguientesRegistros, ...arrayPuntosSuspensivos]
                /*Si el equipo se encuentra en la penúltima posición del array de equipos totales */
            }

            else if ((rankingEquipoUsuarioLogueado + 1) === numberTeams) {
                // 11
                listaSiguientesRegistros = teams.slice(rankingEquipoUsuarioLogueado - 1, rankingEquipoUsuarioLogueado + 1)
                arrayCombinado = [...listaPrimerosRegistros, ...arrayPuntosSuspensivos, ...listaSiguientesRegistros]
            } /*Si el equipo se encuentra en una posición que quedan más de 2 registros después hay que poner... */

            if (rankingEquipoUsuarioLogueado <= numberTeams - 3) {
                // 12
                listaSiguientesRegistros = teams.slice(rankingEquipoUsuarioLogueado - 1, rankingEquipoUsuarioLogueado + 1)
                arrayCombinado = [...listaPrimerosRegistros, ...arrayPuntosSuspensivos, ...listaSiguientesRegistros, ...arrayPuntosSuspensivos]
            }

        }
        var saltoArrayCombinado = false;
        //if( arrayCombinado.includes((item, index)=>item=='...' && index === 6))
        if (arrayCombinado[6] == '...')
            saltoArrayCombinado = true;

        return (
            <>
                <GlobalFonts />
                <Modal
                    className="rankingmodal"
                    title={
                        <Row justify='center' align="top">
                            <Col id="icon" sm={{ span: 4 }} md={{ span: 4 }}>
                                {<img className="modal-icon" src={icon} />}
                            </Col>
                            <Col className="title-background" sm={{ span: 16 }} md={{ span: 16 }}>
                                <div className="title">
                                    RANKING
                                </div>
                                {(this.props.teamsStore.personalRanking != '') && <div className="subtitle">
                                    Ranking personal: {this.props.teamsStore.personalRanking} / {numTeamPlayersByTeam}
                                </div>
                                }

                            </Col>
                        </Row>
                    }
                    closeIcon=
                    {
                        <Button onClick={this.props.close} shape="circle" id="close-button" >
                            <CloseCircleOutlined className="close-circle" />
                        </Button>
                    }
                    open={this.props.show}
                    width="max-content"
                    bodyStyle={{ overflowY: 'scroll' }}
                    closable={true}
                    maskClosable={true}
                    footer={null}
                >

                    <div style={{ backgroundColor: '#0e3e41', textAlign: 'center' }}>
                        <List locale={{ emptyText: (<Empty description="No hay resultados" image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}

                            dataSource={arrayCombinado}
                            renderItem={(item, index) => (
                                <>

                                    <List.Item>

                                        {item != '...' &&
                                            <Row className="list-item box-screen-mode" >
                                                <Col className="data" id="icon" sm={{ span: 3 }} md={{ span: 2 }} lg={{ span: 2 }}>
                                                    <div className="copa">
                                                        {index === 0 && <img src={copaOro} />}
                                                        {index === 1 && <img src={copaPlata} />}
                                                        {index === 2 && <img src={copaBronce} />}
                                                        {index > 2 && <img style={{ paddingRight: '55px' }} />}
                                                    </div>
                                                </Col>
                                                <Col className="data" sm={{ span: 3 }} md={{ span: 2 }} lg={{ span: 2 }}>
                                                    <div className="level" >
                                                        <div className="circle-ranking" >
                                                            {index === 0 && <div className="color-orange">{index + 1}</div>}
                                                            {index >= 1 && index <= 5 && <div className="color-green">{index + 1}</div>}

                                                            {saltoArrayCombinado && index === 6 && <div className="color-green">{rankingEquipoUsuarioLogueado - 1}</div>}
                                                            {saltoArrayCombinado && index === 7 && <div className="color-green">{rankingEquipoUsuarioLogueado}</div>}
                                                            {saltoArrayCombinado && index === 8 && <div className="color-green">{rankingEquipoUsuarioLogueado + 1}</div>}


                                                            {!saltoArrayCombinado && index === 6 && <div className="color-green">{rankingEquipoUsuarioLogueado}</div>}
                                                            {!saltoArrayCombinado && index === 7 && <div className="color-green">{rankingEquipoUsuarioLogueado + 1}</div>}
                                                            {!saltoArrayCombinado && index === 8 && <div className="color-green">{rankingEquipoUsuarioLogueado + 2}</div>}

                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col className="data" sm={{ span: 3 }} md={{ span: 2 }} lg={{ span: 2 }}>
                                                    <div className="avatar-head" >
                                                        <div className="circle-avatar-head" >
                                                            {/* <div className="color-orange" style={{ backgroundColor: item.image_config?.split(',"')[0].split(":")[1].replace(/"/g, '') }}><img className="avatar-team" src={item.image_config == undefined ? headAvatar : "http://localhost:8088/static/teams_avatar/" + item.id + "_cropped.png"} /></div> */}
                                                            <div className="color-orange" style={{ backgroundColor: item.image_config?.split(',"')[0].split(":")[1].replace(/"/g, '') }}><img className="avatar-team" src={item.image_config == undefined ? headAvatar : configApp.getParams().BACKEND_API_URL + "static/teams_avatar/" + item.id + "_cropped.png"} /></div>
                                                            {/* {index != 0 && <div class="color-green"><img src={headAvatar} /></div>} */}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="data" sm={{ span: 6 }} md={{ span: 10 }} lg={{ span: 12 }}>
                                                    <div className="nameTeam" >
                                                        <p>{item.name}</p>
                                                    </div>
                                                    <div className="nameOrganization" >
                                                        <p>{item.organization.name}  </p>
                                                    </div>

                                                </Col>
                                                <Col className="data" sm={{ span: 5 }} md={{ span: 2 }} lg={{ span: 2 }}>
                                                    <div className="points">
                                                        <div className="circle-points">
                                                            {index === 0 && <div class="color-orange"> {item.score}   </div>}
                                                            {index != 0 && <div class="color-green"> {item.score}   </div>}
                                                        </div>
                                                        <br />
                                                    </div>
                                                </Col>
                                            </Row>

                                        }
                                        {item === '...' &&
                                            <Row className="puntosSuspensivos" >
                                                <EllipsisOutlined></EllipsisOutlined>
                                            </Row>
                                        }


                                    </List.Item>
                                </>
                            )}
                        >

                        </List>
                    </div>


                </Modal>
            </>
        )
    }
}

export default withRouter(inject('teamsStore', 'usersStore', 'playersStore')(observer(RankingModal)));